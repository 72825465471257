// Core
import { useLayoutEffect, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Components
import { useInvalidFields } from "../../../hooks/custom/useInvalidFields";
import Select from '../../common/Select';
import Recaptcha from 'react-recaptcha';
import CustomSelect from 'react-select';

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useLists } from "../../../hooks/useLists";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";
import { useOrder } from "../../../hooks/useOrder";

// Instruments
import { isNil, isEmpty, indexOf } from "ramda";
import { getCurrencySign, EMAIL_REGEX, PHONE_REGEX, isFullNameValid, isEpiic, setBodyOverflow } from '../../../helpers/helpers';
import moment from 'moment/moment';
import icon from '../../../theme/images/truste.jpg';
import { domainGAC, epiic, captchaSiteKeyModal } from '../../../helpers/api';

const fieldsObj = {
    vat: '',
    agreement: true,
    full_name: '',
    email: '',
    password: '',
    login_password: '',
    phone: '',
    business_name: '',
    content_budget: 'L',
    country: {},
    type: '',
    role: '',
    referral_code: '',
    industry: [],
    challenge: '',
};

const steps = ['new-account', 'create-account', 'login', 'password'];
export const ModalCreateAccount = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);
    const loginCaptchaRef = useRef(null);
    const resetPassCaptchaRef = useRef(null);

    /* State */
    const [step, setStep] = useState('new-account');
    const [action, setAction] = useState(null);
    const [fields, setFields] = useState(fieldsObj);
    const { vat, agreement, full_name, email, password, login_password, phone, business_name, content_budget, country, type, role, industry, referral_code, challenge } = fields;
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isInvalidCaptcha, setIsInvalidCaptcha] = useState(false);

    /* Hooks */
    const { invalidFields, addField, removeField, setInvalidFields } = useInvalidFields();
    const { login } = useAuth();
    const { orderCurrency } = useOrder();
    const { staticData: { countries: countriesData, company: { roles, types, industries } } } = useLists();
    const { showLoginDialog, loginEmail, createAccountPhone, createAccountFullName, loginAttempt, loginError, isCheckedEmailProposal, isReferralCodeValid, passwordRestoreError, isPasswordRestored, isEmailExist, isLoginFail, setUiState, validateReferralCodeAsync } = useUi();
    const { ipDetails, proposal, activeSub, company: { branding: { host } }, checkEmailExistAsync, createCompanyAsync, updateProposalCompanyAsync, restorePasswordAsync } = useUser();
    const navigate = useNavigate();

    const isProposal = !isEmpty(proposal);
    const isActiveSub = !isEmpty(activeSub);

    /* Actions */
    const onSetCountry = (country) => {
        const item = country && countriesData.length ? countriesData.filter(o => o.code.toLowerCase() === country) : [{ name: '', tax_number: '' }];
        const label = item[0].name;
        const tax_number = item[0].tax_number;

        setFields(state => ({ ...state, country: { value: country, label, tax_number } }));
    };
    const onCloseModal = () => {
        if (step === 'new-account') {
            closeModal();
        } else if (step === 'login') {
            setStep('new-account');
            setFields(state => ({ ...state, email: '', password: '' }));
        } else {
            const index = indexOf(step, steps);
            setStep(steps[index - 1]);
        }
    };
    const onStepChange = ({ currentTarget: { dataset: { step } } }) => {
        setStep(step);
        setInvalidFields([]);

        setUiState('isEmailExist', null);
        setUiState('isLoginFail', false);
        setPos(modalRef);
    };
    const onInputKeyPress = ({ key }) => {
        if (key === 'Enter') onSubmit();
    };
    const validateField = ({ currentTarget: { dataset: { field } } }) => {
        if (field === 'full_name') {
            if (!full_name.includes(' ') || !full_name.split(' ')[1] || !full_name) {
                addField(field);
            }
        } else if (field === 'email') {
            if (!(EMAIL_REGEX.test(email.toLowerCase()))) {
                addField(field);
            }
        } else if (field === 'password') {
            if (password.length < 8) {
                addField(field);
            }
        } else if (field === 'login_password') {
            if (login_password.length < 8) {
                addField(field);
            }
        } else if (field === 'business_name') {
            if (business_name.length < 2) {
                addField(field);
            }
        } else if (field === 'phone') {
            if (!(PHONE_REGEX.test(phone))) {
                addField(field);
            }
        }
    };
    const onInputChange = ({ currentTarget: { value, dataset: { field } } }) => {
        let data = value.replace(/ +(?= )/g, '');
        if (field === 'phone') {
            const phoneNumberRegex = /[^+\d]/g;
            data = data.replace(phoneNumberRegex, '');
        }
        if (field === 'challenge') {
            data = data.replace(/ {2,}/g, ' ');
        }
        let prop = {
            [field]: data,
        };

        setFields(state => ({ ...state, ...prop }))
        removeField(field);

        if (isLoginFail && (field === 'email' || field === 'login_password') && step === 'login') {
            setUiState('isLoginFail', false);
        }
        if (isEmailExist && field === 'email' && step === 'new-account') {
            setUiState('isEmailExist', null);
        }
        if (isPasswordRestored !== null && !isPasswordRestored && field === 'email' && step === 'password') {
            setUiState('isPasswordRestored', null);
        }
        if (field === 'referral_code') {
            if (!isNil(isReferralCodeValid)) setUiState('isReferralCodeValid', null);
        }

    };
    const onFormFieldChange = (prop, value) => {
        setFields(state => ({ ...state, [prop]: value }));
        removeField(prop);
    };
    const onCountryChange = (value) => {
        setFields(state => ({ ...state, country: value, vat: value.tax_number === 'eu' ? state.vat : '' }));
        removeField('country');
    };
    const onAgreementChange = () => {
        setFields(state => ({ ...state, agreement: !state.agreement }));
        removeField('agreement');
    };
    const onCheckEmailExist = () => {
        if (step === 'new-account') {
            if (EMAIL_REGEX.test(email.toLowerCase())) {
                checkEmailExistAsync(email);
            } else {
                addField('email');
            }
        }
    };
    const changePassField = () => {
        setIsPassVisible(state => !state);
    };
    const verifyReCaptcha = (response) => {
        if (response) {
            setIsVerified(true);
            setIsInvalidCaptcha(false);
        }
    };
    const onloadCallback = () => { };
    const onReferralCodeBlur = () => {
        if (!isEmpty(referral_code)) {
            validateReferralCodeAsync(referral_code);
        }
    };
    const onIndustryChange = (parameter, value, _, title) => {
        let data = [];
        if (industry.some(o => `${o.value}` === value)) {
            data = industry.filter(o => `${o.value}` !== value);
        } else {
            data = [...industry, { value, title }];
        }
        setFields(state => ({ ...state, 'industry': data }));
        removeField(parameter);
    };
    /* Submit */
    const validateFields = () => {
        let fields = [];

        if (step === 'new-account') {
            if (!isFullNameValid(full_name)) {
                fields.push('full_name');
            }
            if (!(EMAIL_REGEX.test(email.toLowerCase()))) {
                fields.push('email');
            }
            if (password.length < 8) {
                fields.push('password');
            }
            if (!(PHONE_REGEX.test(phone))) {
                fields.push('phone');
            }
        } else if (step === 'create-account') {
            if (business_name.length < 2) {
                fields.push('business_name');
            }
            if (!content_budget && !isActiveSub) {
                fields.push('content_budget');
            }
            if (isEmpty(country)) {
                fields.push('country');
            }
            if (isEmpty(role)) {
                fields.push('role');
            }
            if (isEmpty(type)) {
                fields.push('type');
            }
            if (isEmpty(industry)) {
                fields.push('industry');
            }
            if (!agreement) {
                fields.push('agreement');
            }
            if (!isEmpty(referral_code) && (isNil(isReferralCodeValid) || !isReferralCodeValid)) {
                fields.push('referral_code');
            }
            if (isEmpty(challenge.replace(/ +/g, ''))) {
                fields.push('challenge');
            }
        } else if (step === 'login') {
            if (!(EMAIL_REGEX.test(email.toLowerCase()))) {
                fields.push('email');
            }
            if (login_password.length < 8) {
                fields.push('login_password');
            }
        } else if (step === 'password') {
            if (!(EMAIL_REGEX.test(email.toLowerCase()))) {
                fields.push('email');
            }
        }

        return fields;
    };
    const onSubmit = () => {
        const fields = validateFields();

        if (step === 'create-account' && isNil(isReferralCodeValid) && !isEmpty(referral_code)) {
            setAction('submit');
        } else {
            if (isEmpty(fields)) {
                if (step === 'create-account') {
                    const nameArray = full_name.split(' ');
                    const user_first_name = nameArray.splice(0, 1)[0];
                    const timezone = moment().format('Z');
                    const { amount } = activeSub;
                    const budget = isActiveSub ? Number(amount) < 200 ? 'S' : Number(amount) > 1000 ? 'L' : 'M' : content_budget;
                    const company = {
                        user_first_name,
                        user_last_name: nameArray.join(' '),
                        user_email: email,
                        user_password: password,
                        user_timezone: timezone,
                        company_name: business_name,
                        country_code: country.value,
                        currency: getCurrencySign(orderCurrency),
                        phone,
                        content_budget: budget,
                        billing_company_name: business_name,
                        billing_country_code: country.value,
                        billing_email: email,
                        billing_tax_number: vat,
                        account_name: 'Untitled account',
                        account_primary_contact: full_name,
                        account_primary_contact_timezone: timezone,
                        account_billing_email: email,
                        account_billing_contact_person: full_name,
                        account_billing_company_name: business_name,
                        account_billing_country_code: country.value,
                        type,
                        role,
                        referral_code,
                        challenge,
                        industry: JSON.stringify(industry.map(o => o.value)),
                    };
                    const updatedCompany = {
                        user: {
                            first_name: user_first_name,
                            last_name: nameArray.join(' '),
                            email,
                            password,
                            phone,
                            timezone
                        },
                        company: {
                            company_name: business_name,
                            billing_tax_number: vat,
                            country_code: country.value,
                            content_budget: budget,
                        }
                    };
                    if (!isEmpty(isCheckedEmailProposal)) {
                        setUiState('isCheckedEmailProposal', { ...isCheckedEmailProposal, company: updatedCompany });
                        login(isCheckedEmailProposal, navigate);
                    } else {
                        if (isEmpty(proposal)) {
                            createCompanyAsync(company, navigate);
                        } else {
                            updateProposalCompanyAsync(updatedCompany, true);
                        }
                    }
                } else if (step === 'login' && !isLoginFail) {
                    if (loginAttempt > 2) {
                        if (isVerified) {
                            login({ email, password: login_password }, navigate);
                            setIsVerified(false);
                            loginCaptchaRef.current.reset();
                        } else {
                            setIsInvalidCaptcha(true);
                        }
                    } else {
                        login({ email, password: login_password }, navigate);
                    }
                } else if (step === 'password') {
                    if (isVerified) {
                        restorePasswordAsync(email);
                        setIsVerified(false);
                        resetPassCaptchaRef.current.reset();
                    } else {
                        setIsVerified(true);
                    }
                } else if (step === 'new-account' && !isEmailExist) {
                    const index = indexOf(step, steps);
                    setStep(steps[index + 1]);
                }
            } else {
                setInvalidFields(fields);
                setIsInvalidCaptcha(!isVerified);
            }
        }
    };

    useLayoutEffect(() => {
        const { user } = proposal;
        let email, phone, full_name = '';
        setBodyOverflow('hidden');

        if (loginEmail) { email = loginEmail; }
        if (createAccountPhone) { phone = createAccountPhone; }

        if (!isNil(user)) {
            email = user.email;
            if (!isNil(user.first_name)) { full_name = user.first_name; }
            if (!isNil(user.last_name)) { full_name = `${full_name} ${user.last_name}`; }
        }
        else if (createAccountFullName) {
            full_name = createAccountFullName
        }

        if(showLoginDialog) {
            setStep('login');
        }

        if (loginEmail || !isNil(user)) {
            setStep(!isNil(user) ? 'new-account' : isEmailExist ? 'login' : 'new-account');
            setFields(state => ({ ...state, email, full_name }));
        }

        if (phone || !isNil(user)) {
            setFields(state => ({ ...state, phone }));
        }

        return () => {
            setBodyOverflow('auto');
            setUiState('isPasswordRestored', null);
            setUiState('loginEmail', '');
            setUiState('createAccountPhone', '');
            setUiState('createAccountFullName', '');
            setUiState('isEmailExist', null);
            //             actions.setDataState('isProposalCompanyUpdated',false);
            //             actions.setDataState('isCheckedEmailProposal',{});
        };
    }, []);
    useLayoutEffect(() => {
        if (!isEmpty(ipDetails) && ipDetails.country_code2) {
            onSetCountry(ipDetails.country_code2.toLowerCase());
        }
    }, [ipDetails]);
    useLayoutEffect(() => {
        setPos(modalRef);
    }, [step, invalidFields]);
    useEffect(() => {
        if (!isNil(isReferralCodeValid) && action === 'submit') {
            if (isReferralCodeValid) {
                onSubmit();
            } else {
                setAction(null);
            }
        }
    }, [isReferralCodeValid, action]);

    /* Fields */
    const inputProps = { 'data-hj-whitelist': true, type: 'text', onKeyPress: onInputKeyPress, onBlur: validateField, onChange: onInputChange };
    const getTitle = () => {
        return <h1>{step === 'login' ? 'Log in' : step === 'password' ? 'Recover password' : isProposal ? 'Confirm account details' : 'Create a new account'}</h1>
    };
    const getNameField = () => {
        return <>
            <label>Full name</label>
            <input {...inputProps} data-field='full_name' className={`gac-input ${invalidFields.includes('full_name') ? 'gac-invalid' : ''}`} value={full_name} />
            {invalidFields.includes('full_name') && <div className='gac-error'>Please enter your full name</div>}
        </>;
    };
    const getEmailField = () => {
        return <>
            <label>Email</label>
            <input {...inputProps} data-field='email' className={`gac-input ${invalidFields.includes('email') || isEmailExist ? 'gac-invalid' : ''}`} value={email} onBlur={onCheckEmailExist} />
            {invalidFields.includes('email') && <div className='gac-error'>Please enter a valid email</div>}
            {isEmailExist && <div className='gac-error'>Email address is already registered. {isEmpty(proposal) && <><span data-step='login' onClick={onStepChange}>Log in</span> instead.</>}</div>}
        </>;
    };
    const getPasswordField = () => {
        return <>
            <label>Password</label>
            <input {...inputProps} data-field='password' className={`gac-input ${invalidFields.includes('password') ? 'gac-invalid' : ''}`} type='password' value={password} />
            {invalidFields.includes('password') && <div className='gac-error'>Minimum password length is 8 characters</div>}
        </>;
    };
    const getPhoneField = () => {
        return <>
            <label>Phone</label>
            <input {...inputProps} data-field='phone' className={`gac-input ${invalidFields.includes('phone') ? 'gac-invalid' : ''}`} value={phone} />
            {invalidFields.includes('phone') && <div className='gac-error'>Please enter a valid phone number</div>}
        </>;
    };
    const getTrusteLogo = () => <div className='gac-truste-logo'><img src={icon} alt="TrustArc logo" />Your private information is safe with us</div>;
    const getBusinessNameField = () => {
        return <>
            <label>Business name</label>
            <input {...inputProps} data-field='business_name' className={`gac-input ${invalidFields.includes('business_name') ? 'gac-invalid' : ''}`} value={business_name} />
            {invalidFields.includes('business_name') && <div className='gac-error'>Please enter your business name</div>}
        </>;
    };
    const getVatField = () => {
        if (country.tax_number !== 'eu') return null;

        return <>
            <label>VAT number</label>
            <input {...inputProps} data-field='vat' className='gac-input' value={vat} onBlur={() => { }} />
        </>;
    };
    const getCountryField = () => {
        const countries = countriesData.filter(o => o.code).map(o => ({ label: o.name, value: o.code, tax_number: o.tax_number }));
        return <>
            <label>Country</label>
            <div className={`gac-custom-select ${invalidFields.includes('country') ? 'gac-invalid' : ''}`}>
                <CustomSelect placeholder='' classNamePrefix='gac-select' value={country} onChange={onCountryChange} options={countries} />
            </div>
            {invalidFields.includes('country') && <div className='gac-error'>Please select your country</div>}
        </>;
    };
    const getCompanyTypeField = () => {
        const typesData = types.filter(o => !!o.active).sort((a, b) => a.sort - b.sort).map(o => ({ value: `${o.id}`, title: o.name }));
        return <>
            <label>Company type</label>
            <Select
                isMultiple={false}
                items={typesData}
                value={type}
                title={type ? types.filter(o => `${o.id}` === type)[0].name : ''}
                parameter='type'
                invalid={invalidFields.includes('type')}
                onChange={onFormFieldChange} />
            {invalidFields.includes('type') && <div className='gac-error'>Please select your company type</div>}
        </>;
    };
    const getRoleField = () => {
        const rolesData = roles.filter(o => !!o.active).sort((a, b) => a.sort - b.sort).map(o => ({ value: `${o.id}`, title: o.name }));
        return <>
            <label>Role</label>
            <Select
                isMultiple={false}
                items={rolesData}
                value={role}
                title={role ? roles.filter(o => `${o.id}` === role)[0].name : ''}
                parameter='role'
                invalid={invalidFields.includes('role')}
                onChange={onFormFieldChange} />
            {invalidFields.includes('role') && <div className='gac-error'>Please select your role</div>}
        </>;
    };
    const getIndustryField = () => {
        const industriesData = industries.filter(o => !!o.active).sort((a, b) => a.sort - b.sort).map(o => ({ value: `${o.id}`, title: o.name }));

        return <>
            <label>Industry</label>
            <Select
                isSearch={true}
                isMultiple={true}
                items={industriesData}
                value={industry.map(o => o.value)}
                title={industry.map(o => o.title)}
                parameter='industry'
                invalid={invalidFields.includes('industry')}
                onChange={onIndustryChange} />
            {invalidFields.includes('role') && <div className='gac-error'>Please select your company industry</div>}
        </>;
    };
    const getReferralCodeField = () => {
        return <>
            <label>Referral code</label>
            <input
                data-hj-whitelist
                data-field='referral_code'
                className={`gac-input ${!isNil(isReferralCodeValid) && !isReferralCodeValid ? 'gac-invalid' : ''}`}
                type='text'
                value={referral_code}
                onBlur={onReferralCodeBlur}
                onChange={onInputChange} />
            {!isNil(isReferralCodeValid) && !isReferralCodeValid && <div className='gac-error'>Please enter a valid referral code</div>}
        </>;
    };
    const getChallengeField = () => {
        return <>
            <label>What’s the biggest challenge in your business right now?</label>
            <textarea
                className={invalidFields.includes('challenge') ? 'gac-invalid' : ''}
                data-field='challenge'
                value={challenge}
                onChange={onInputChange} />
        </>;
    };
    const getAgreementField = () => {
        return <div className='gac-agreement'>
            <input id='agreement' type='checkbox' onChange={onAgreementChange} checked={agreement} />
            <label className={invalidFields.includes('agreement') ? 'gac-invalid' : ''} htmlFor='agreement' />
            I agree to the <a rel="noopener noreferrer" target='_blank' href={`https://${isEpiic(host) ? epiic : domainGAC}.com/terms-conditions`}>Terms of Use</a> and <a target='_blank' rel="noopener noreferrer" href={`https://${isEpiic(host) ? epiic : domainGAC}.com/privacy-policy`}>Privacy Policy</a>
        </div>;
    };
    const getLoginEmailField = () => {
        return <>
            <label>Your email</label>
            <input {...inputProps} data-field='email' className={`gac-input ${invalidFields.includes('email') || isLoginFail ? 'gac-invalid' : ''}`} value={email} />
            {invalidFields.includes('email') && <div className='gac-error'>Please enter a valid email</div>}
            {isLoginFail && <div className='gac-error'>{loginError ? loginError : 'Incorrect email and/or password'}</div>}
        </>;
    };
    const getLoginPasswordField = () => {
        return <>
            <label>Password</label>
            <div className='gac-input-wrap'>
                <input {...inputProps} data-field='login_password' className={`gac-input ${invalidFields.includes('login_password') || isLoginFail ? 'gac-invalid' : ''}`} type={isPassVisible ? 'text' : 'password'} value={login_password} />
                <span onClick={changePassField} className={`gac-login-icon-1 ${isPassVisible ? 'gac-visible' : ''}`} />
            </div>
            {invalidFields.includes('login_password') && <div className='gac-error'>Minimum password length is 8 characters</div>}
            {isLoginFail && <div className='gac-error'>Incorrect email and/or password</div>}
            <div className='gac-forgot-password'>
                <span data-step='password' onClick={onStepChange}>Forgot password?</span>
            </div>
        </>;
    };
    const getRecaptcha = (ref) => {
        return <Recaptcha ref={ref} render='explicit' sitekey={captchaSiteKeyModal} verifyCallback={verifyReCaptcha} onloadCallback={onloadCallback} />;
    };
    const getLoginRecaptchaField = () => {
        if (loginAttempt < 3) return null;
        return <>
            {getRecaptcha(loginCaptchaRef)}
            {isInvalidCaptcha && <div className='gac-error'>Please verify that you are a human</div>}
        </>;
    };
    const getResetPassEmailField = () => {
        return <>
            <label>Email</label>
            <input {...inputProps} data-field='email' className={`gac-input ${invalidFields.includes('email') || (isPasswordRestored !== null && !isPasswordRestored) ? 'gac-invalid' : ''}`} value={email} />
            {invalidFields.includes('email') && <div className='gac-error'>Please enter a valid email</div>}
            {isPasswordRestored !== null && !isPasswordRestored && <div className='gac-error'>{passwordRestoreError ? passwordRestoreError : 'Email not found'}</div>}
            <div className='gac-forgot-password'>
                <span data-step='login' onClick={onStepChange}>Log in</span>
            </div>
        </>;
    };
    const getResetPassRecaptchaField = () => {
        return <>
            {getRecaptcha(resetPassCaptchaRef)}
            {isInvalidCaptcha && <div className='gac-error'>Please verify that you are a human</div>}
        </>;
    };
    /* Btns */
    const getBtn = () => <span onClick={onSubmit} className='gac-btn gac-btn-m'>{step === 'new-account' ? 'Next' : step === 'login' ? 'Log in' : step === 'password' ? 'Email instructions' : isProposal ? 'Confirm' : 'Create an account'}</span>;
    const getBtns = () => {
        if (isProposal) return null;

        return <div className='gac-have-account'>
            {step === 'login' || step === 'password'
                ? <>Don't have an account? <span data-step='new-account' onClick={onStepChange}>Sign up</span></>
                : <>Already have an account? <span data-step='login' onClick={onStepChange}>Log in</span></>}
        </div>;
    };

    /* Html */
    const getNewAccountForm = () => {
        return <>
            {getNameField()}
            {getEmailField()}
            {getPasswordField()}
            {getPhoneField()}
            {getTrusteLogo()}
        </>;
    };
    const getCreateAccountForm = () => {
        return <>
            {getBusinessNameField()}
            {/*{getVatField()}*/}
            {getCountryField()}
            {getCompanyTypeField()}
            {getRoleField()}
            {getIndustryField()}
            {/*{getReferralCodeField()}*/}
            {getChallengeField()}
            {getAgreementField()}
        </>;
    };
    const getLoginForm = () => {
        return <>
            {getLoginEmailField()}
            {getLoginPasswordField()}
            {getLoginRecaptchaField()}
        </>;
    };
    const getPasswordForm = () => {
        return <>
            {getResetPassEmailField()}
            {getResetPassRecaptchaField()}
        </>;
    };
    const getContent = () => {
        if (isPasswordRestored) return null;

        const content = {
            'new-account': getNewAccountForm(),
            'create-account': getCreateAccountForm(),
            'login': getLoginForm(),
            'password': getPasswordForm(),
        };
        return <>
            <span className='gac-close-modal' onClick={onCloseModal} />
            {getTitle()}
            {content[step]}
            {getBtn()}
            {getBtns()}
        </>;
    };
    const getSuccessScreen = () => {
        return step === 'password' && isPasswordRestored && <>
            <span className='gac-success-icon'><i /></span>
            <h1>Check your email (and spam) for instructions</h1>
        </>;
    };

    return <div ref={modalRef} className='gac-modal gac-create-account-modal'>
        {getContent()}
        {getSuccessScreen()}
    </div>;
};
