// Core
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';

// Components
import Creatable from 'react-select/creatable';
import { ProjectType } from '../../Manage/ProjectType';

// Icons
import Pencil from '../../icons/Pencil';
import Share from '../../icons/Share';
import Support from '../../icons/Support';
import Duplicate from '../../icons/Duplicate';
import View from '../../icons/View';
import Message from '../../icons/Message';
import CancelProject from "../../icons/CancelProject";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useLists } from "../../../hooks/useLists";
import { useModals } from "../../../hooks/useModals";
import { useProject } from "../../../hooks/useProject";
import { useUser } from "../../../hooks/useUser";
import { useUi } from "../../../hooks/useUi";

// Instruments
import {
    getAppTitle,
    getFormat,
    toLocaleString,
    getDuration,
    getDesignFormat,
    isSelfClientFn,
    isClientFn,
    isFetching,
    onOutsideElClick
} from "../../../helpers/helpers";
import { isNil, isEmpty, equals, includes } from 'ramda';
import moment from 'moment/moment';

export const Overview = ({ company }) => {
    /* Ref */
    const listRef = useRef(null);
    const listWrapRef = useRef(null);

    /* State */
    const [isTagsEditing, setIsTagsEditing] = useState(false);
    const [stateTags, setStateTags] = useState(null);
    const [isMoveProjectList, setIsMoveProjectList] = useState(false);

    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const { staticData: { formats }} = useLists();
    const { setModal } = useModals();
    const {
        details,
        tags: tags_list,
        pitches,
        setProjectState,
        extendProjectDeadlineAsync,
        fetchProjectDetailsAsync,
        fetchProjectPitchesAsync,
        updateProjectAsync,
        createTagAsync,
        moveProjectAsync
    } = useProject();
    const {
        project_id,
        format: currentFormat,
        word_count,
        idea_count,
        level,
        topic,
        currency,
        client_amount,
        status,
        rating,
        order_on,
        job_type,
        talent,
        deadline,
        writer_name,
        created_by,
        creator_logo,
        designer_logo,
        publish_url,
        extended,
        project_tags,
        invite_writers,
        files,
        revision,
        pay_type
    } = details;
    const { details: { accounts, user_role }} = useUser();
    const { isMobile, fetching, setUiState } = useUi();
    const navigate = useNavigate();

    const isKeywords = job_type === 'keywords';
    const isIdeas = job_type === 'ideas';
    const isProof = job_type === 'proofreading';
    const isDesign = job_type === 'design';
    const isMotion = job_type === 'motion';
    const isWebAudit = job_type === 'webaudit';
    const isSeoAudit = job_type === 'seoaudit';

    /* Actions */
    const extendDeadline = () => {
        if ( isFetching(fetching) ) return;

        if ( extended === 'yes' ) {
            setModal('extendProject');
        } else {
            extendProjectDeadlineAsync(project_id);
        }
    };
    const onUpdateProject = (obj = {}) => {
        const data = { ...details };
        let writers = isNil(invite_writers) ? [] : JSON.parse(invite_writers).map(({ writer_uid }) => writer_uid);
        if ( !invite_writers || !invite_writers.length ) {
            delete data.invite_writer_active_for;
        }

        updateProjectAsync({
            ...data,
            ...obj,
            files: isEmpty(files) ? '' : files.map(o => o.file_id).join(','),
            project_tags: isEmpty(JSON.parse(stateTags)) ? [''] : JSON.parse(stateTags),
            invite_writers: writers,
        }, { name: 'update_project', job_type });
    };
    const onDuplicateProject = () => {
        fetchProjectDetailsAsync(project_id, 'duplicating', navigate);
    };
    const onCancelProject = () => {
        setModal('cancelDesignProject');
    };
    const onMessageSupportClick = () => {
        setModal('messageSupport');
    };

    /* useEffect */
    useLayoutEffect(() => {
        document.title = getAppTitle('Project overview', company);

        fetchProjectPitchesAsync({ project_id });
        return () => {
            setProjectState('pitches', null);
        };
    }, []);
    useEffect(() => {
        if ( !isNil(project_tags) ) {
            setStateTags(project_tags);
        }
    }, [project_tags]);
    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,listWrapRef.current,() => { setIsMoveProjectList(false) });
        };

        if ( isMoveProjectList ) {
            if ( !isNil(listRef.current) ) {
                const h = window.innerHeight;
                const { top, height } = listRef.current.getBoundingClientRect();
                if ( top + height > h ) {
                    listRef.current.style.top = "-368px";
                }
            }
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isMoveProjectList]);

    /* Html */
    const getTopic = () => {
        if ( isMobile ) return null;
        return <div className="gac-project-title">{ topic }</div>;
    };
    const getJobType = () => {
        return <div className="gac-overview-field">
            <div className="gac-overview-label">Job type:</div>
            <div className="gac-overview-data gac-with-format">
                <ProjectType type = { talent === 'bloggers' && job_type === 'writing' ? 'blogging' : job_type } />
                <span style={{ textTransform: 'capitalize' }}>{ talent === 'bloggers' && job_type === 'writing' ? 'blogging' : job_type }</span>
            </div>
        </div> ;
    };
    const getProjectId = () => {
        return <div className="gac-overview-field gac-field-project-id">
            <div className="gac-overview-label">Project ID:</div>
            <div className="gac-overview-data">{ project_id }</div>
        </div> ;
    };
    const getFormatField = () => {
        const clientAmountStr = isClientFn(user_role) || isSelfClientFn(user_role) ? '' : `, ${currency}${toLocaleString(Number(client_amount.replace(',','')))}`;
        const levelStr = isMotion || isSeoAudit ? '' : `${level.charAt(0).toUpperCase() + level.slice(1)}`;

        let format = '';
        if ( isKeywords ) {
            format = `Keyword research${clientAmountStr}`;
        } else if ( isSeoAudit ) {
            format = `SEO audit${clientAmountStr}`;
        } else if ( isWebAudit ) {
            format = `Website audit${clientAmountStr}`;
        } else if ( isIdeas ) {
            format = `${idea_count} topic ideas, ${levelStr}${clientAmountStr}`;
        } else if ( isDesign || isMotion ) {
            format = getDesignFormat(formats[job_type], currentFormat);
        } else {
            let countStr = `${toLocaleString(word_count.toString())}w`;
            format = `${getFormat(formats['writing'], currentFormat)} ${countStr}, ${levelStr}${clientAmountStr}`;
        }

        return <div className="gac-overview-field">
            <div className="gac-overview-label">{ isKeywords ? 'Type:' : 'Format:' }</div>
            <div className="gac-overview-data gac-with-format">
                { format }
            </div>
        </div> ;
    };
    const getStatus = () => {
        const statusStr = status === 'matching' ? 'Queued' : isDesign ? status === 'revision' ? 'Designing' : status === 'editing' ? 'Review' : status : status;
        const dueIn = deadline ? getDuration(moment.utc(deadline).diff(moment().utc())).duration : '';

        return <div className="gac-overview-field">
            <div className="gac-overview-label">Status:</div>
            <div className="gac-overview-data gac-with-status">
                <i className = { `gac-overview-status ${status}` }><i/></i>
                <span className = 'gac-status-name'>
                    { status === 'approved'
                        ? !isNil(publish_url)
                            ? publish_url === 'manual'
                                ? 'Published manually'
                                : <>Published on <a href={`https://${ publish_url }`} rel = 'noopener noreferrer' target='_blank'>{ publish_url }</a></>
                            : isNil(rating)
                                ? 'Auto-approved'
                                : 'Approved'
                        : <div style={{ textTransform: 'capitalize' }}>{ statusStr }</div> }
                </span>
                <div className = 'gac-extend-date'>
                    { status === 'approval'
                        ? <>
                            <i className = 'gac-extend-date-line'/>
                            <em>{ `Approval due in ${ dueIn }` }</em>
                            <i onClick = { extendDeadline } className='gac-extend-deadline'>Extend</i>
                        </>
                        : null }
                </div>
            </div>
        </div> ;
    };
    const getCreatedBy = () => {
        return <div className="gac-overview-field">
            <div className="gac-overview-label">Created by</div>
            <div className="gac-overview-data gac-with-img">
                <span>{ creator_logo ? <img className='gac-img' src={ creator_logo } alt="Creator logo"/> : <div className="gac-no-img"/> }</span>
                <span>{ created_by }</span>
            </div>
        </div> ;
    };
    const getOrderedOn = () => {
        const orderOn = order_on ? moment(order_on).format('MMM D, YYYY') : '';

        return <div className="gac-overview-field">
            <div className="gac-overview-label">Ordered on:</div>
            <div className="gac-overview-data">
                <span>{ orderOn }</span>
            </div>
        </div> ;
    };
    const getTalentLogo = () => {
        return <div className="gac-overview-field">
            <div className="gac-overview-label">{ isKeywords ? 'Consultant:' : isProof ? 'Proofreader' : isDesign ? 'Designer' : 'Writer:' }</div>
            <div className="gac-overview-data gac-with-img">
                <span>{ isDesign && !isNil(designer_logo) && !isEmpty(designer_logo)
                    ? <img className='gac-img' src = { designer_logo } alt="Designer logo"/>
                    : writer_name
                        ? <div className="gac-no-img"/>
                        : null }
                </span>
                <span>{ writer_name ? writer_name : 'To be assigned' }</span>
            </div>
        </div> ;
    };
    const getTags = () => {
        if ( isKeywords || isDesign ) return null;

        /* Actions */
        const onTagsBlur = () => {
            if ( isFetching(fetching) ) return;

            setIsTagsEditing(false);
            if ( !equals(project_tags, stateTags) ) {
                onUpdateProject();
            }
        };
        const onTagsChange = (value) => {
            setStateTags(JSON.stringify(isNil(value) ? [] : value.map(o => o.value)));
        };
        const onTagsCreate = (tag) => {
            if ( isFetching(fetching) ) return;

            let data = JSON.parse(stateTags);
            data = isEmpty(data) || isNil(data) ? [] : data;

            createTagAsync(tag);
            setStateTags(JSON.stringify([...data, tag]));
        };
        const onTagsInputChange = (value) => {
            return value.replace(/[^0-9a-zA-Z_]/gi, '').substr(0, 25);
        };
        const formatCreateLabel = (value) => {
            return `Create '${value}'`;
        };
        const noTagsMessage = () => {
            return 'Type in a new tag';
        };

        /* Html */
        let tags = stateTags ? JSON.parse(stateTags) : [];
        const tagsData = tags.map((item, i) => <div key = {i} className="gac-project-tag">{ item }</div>);

        const getTagsField = () => {
            if ( !isTagsEditing ) return null;
            return <Creatable
                    onInputChange = { onTagsInputChange }
                    formatCreateLabel = { formatCreateLabel }
                    placeholder = ''
                    autoFocus = { true }
                    className = 'gac-tags-select'
                    classNamePrefix = 'gac-select'
                    isMulti = { true }
                    value = { tags.map(item => ({ value: item, label: item })) }
                    onBlur = { onTagsBlur }
                    onChange = { onTagsChange }
                    onCreateOption = { onTagsCreate }
                    noOptionsMessage = { noTagsMessage }
                    options = { tags_list.map(o => ({ value: o.value, label: o.title })) }/>;
        };
        const getTagsData = () => {
            if ( isTagsEditing ) return null;
            return <>
                { isEmpty(tagsData) ? null : <div className = "gac-project-tags" style={{ paddingRight: 11 }}>{ tagsData }</div> }
                <i className = "gac-overview-edit-icon" onClick = { () => { setIsTagsEditing(true) } } />
            </>;
        };

        return <div className="gac-overview-field gac-custom-select gac-mb-4">
            <div className="gac-overview-label">Tags:</div>
            <div className="gac-overview-data gac-with-icon-tags">
                { getTagsField() }
                { getTagsData() }
            </div>
        </div> ;
    };
    // const getWrittenBy = () => {
    //     // const writtenBy = written_by ? moment(written_by).format('MMM D, YYYY') : '';
    //
    //     // <div className="gac-overview-field">
    //     //     <div className="gac-overview-label">{ `Planned ${writtenByLabel} by:` }</div>
    //     //     <div className="gac-overview-data">{ writtenBy }</div>
    //     // </div>
    // };
    // const getPlannedPublish = () => {
    //     // componentWillUnmount() {
    //     //     const { actions: { resetProjectData, setDataState }} = this.props;
    //     //     document.removeEventListener('click', this._onPickerOutsideClick);
    //     // }
    //
    //     // _onPickerOutsideClick = (e) => {
    //     //     let target = e.target;
    //     //     const { input } = this.dayPicker;
    //     //
    //     //     do {
    //     //         if (target === input.parentElement) {
    //     //             return;
    //     //         }
    //     //         target = target.parentNode;
    //     //     } while (target);
    //     //
    //     //     this.setState({
    //     //         editingField: '',
    //     //     }, () => {
    //     //         document.removeEventListener('click', this._onPickerOutsideClick);
    //     //     });
    //     // };
    //
    //     // _onDayPickerChange = (day, m, { input: { id:prop }}) => {
    //     //     this.setState({
    //     //         editingField: '',
    //     //     }, () => {
    //     //         document.removeEventListener('click', this._onPickerOutsideClick);
    //     //         onUpdateProject({
    //     //             [prop]: moment(day).format('YYYY-MM-DD HH:mm:ss'),
    //     //         })
    //     //     });
    //     // };
    //
    //     // const writtenByLabel = isKeywords ? 'completion' : isProof ? 'proofread' : isDesign ? 'designed' : 'written';
    //     //const plannedPublish = planned_publish ? moment(planned_publish).format('MMM D, YYYY') : '';
    //
    //     // <div className="gac-overview-field gac-mb-6">
    //     //     <div className="gac-overview-label">Planned publish on:</div>
    //     //     <div className="gac-overview-data gac-data-planned-publish">
    //     //         { editingField === 'planned_publish'
    //     //         ? <DayPickerInput
    //     //         dayPickerProps = {{
    //     //         disabledDays: {
    //     //         before: moment(writtenBy, 'MMM D, YYYY').diff(moment()) > 0 ? new Date(writtenBy) : new Date(),
    //     //         }
    //     //         }}
    //     //         classNames = {{
    //     //         container: 'gac-daypicker-container',
    //     //         overlayWrapper: 'DayPickerInput-OverlayWrapper gac-daypicker-wrap',
    //     //         overlay: 'DayPickerInput-Overlay',
    //     //         }}
    //     //         inputProps = {{
    //     //         'data-hj-whitelist': true,
    //     //         id: 'planned_publish',
    //     //         className: `gac-input gac-input-calendar`,
    //     //         readOnly:  true,
    //     //         }}
    //     //         ref={ref => this.dayPicker = ref}
    //     //         format = 'MMM D, YYYY'
    //     //         formatDate = { formatDate }
    //     //         parseDate = { parseDate }
    //     //         placeholder = { '' }
    //     //         showOverlay = { true }
    //     //         keepFocus = { false }
    //     //         value = { plannedPublish }
    //     //         onDayChange = { this._onDayPickerChange }/>
    //     //         : <>
    //     //         <span style={{ marginRight: 11 }}>{ plannedPublish }</span>
    //     //         <i className = "gac-overview-edit-icon" data-field = 'planned_publish' onClick={ this._setEditingField } />
    //     //         </>}
    //     //     </div>
    //     // </div>
    // };
    const getEditBriefBtn = () => {
        if ( !includes(status, 'pitching/matching') ) return null;
        if ( job_type === 'design' && revision ) return null;

        const onEditBrief = () => {
            setUiState('shouldEditBrief',true);
            navigate(`/project/${project_id}/brief`);
        };

        return(
            <div className="gac-btn-v3" onClick = { onEditBrief }><i className="gac-svg"><Pencil/></i><span>Edit brief</span></div>
        );
    };
    const getBtns = () => {
        /* Actions */
        const onProjectMove = ({ currentTarget: { dataset: { id, name }}}) => {
            moveProjectAsync(project_id, status, id, name, navigate);
        };
        const onMessageWriter = () => {
            setUiState('shouldOpenMessageForm',true);
        };
        const onNewAccountClick = () => {
            navigate('/account/new');
        };
        const toggleMoveProjectList = () => {
            setIsMoveProjectList(state => !state);
        };

        const isMessageWriter = 'writing|designing|editing|approved|revision|cancelled'.includes(status) && !isClientFn(user_role) && !isSelfClientFn(user_role);
        const isDuplicateBtn = 'pitching|matching|writing|designing|editing|approved|revision|approval|cancelled'.includes(status);
        const isReviewPitches = status === 'pitching' && !isEmpty(pitches);
        const isReviewContent = status === 'approval';
        const isCancelBtn = 'pitching|matching|designing|revision|queued'.includes(status) && (isDesign || isMotion ) && pay_type === 'subscription';

        const accountsData = accounts
            .filter(o => o.account_id !== accountId )
            .filter(o => o.active === 1 )
            .map(o => {
                return <li
                    key = { o.account_id }
                    data-id = { o.account_id }
                    data-name = { o.account_name }
                    onClick = { onProjectMove }>
                    { o.account_name }
                </li>
            });

        return(
            <div className="gac-project-btns">
                { isReviewPitches && <Link className="gac-btn-v3" to = { `/project/${project_id}/messages` }><i className="gac-svg"><View/></i><span>Review pitches</span></Link> }
                { isReviewContent && <Link className="gac-btn-v3" to = { `/project/${project_id}/content` }><i className="gac-svg"><View/></i><span>Review content</span></Link> }
                { isReviewContent && <Link className="gac-btn-v3" to = { `/project/${project_id}/sharing` }><i className="gac-svg"><Share/></i><span>Share project</span></Link> }
                { isMessageWriter && <Link className="gac-btn-v3" onClick = { onMessageWriter } to = { `/project/${project_id}/messages` }><i className="gac-svg"><Message/></i><span>Message { isDesign ? 'designer' : 'writer' }</span></Link> }
                { getEditBriefBtn() }
                { isDuplicateBtn && <div className="gac-btn-v3" onClick = { onDuplicateProject }><i className="gac-svg"><Duplicate/></i><span>Duplicate project</span></div> }
                { !isClientFn(user_role) && !isSelfClientFn(user_role) && <div className="gac-btn-v3" onClick = { onMessageSupportClick }><i className="gac-svg"><Support/></i><span>Message support</span></div> }
                { isCancelBtn && <div className="gac-btn-v3" onClick = { onCancelProject }>
                    <i className="gac-svg"><CancelProject/></i>
                    <span>Cancel project</span></div> }
                { accounts.length > 1 && <div className="gac-move-project-block" ref = { listWrapRef }>
                    <span onClick = { toggleMoveProjectList }><i/></span>
                    { isMoveProjectList && <div className="gac-move-project-list" ref = { listRef }>
                        <p>Move project to:</p>
                        <ul>
                            { accountsData }
                            <li onClick = { onNewAccountClick } >+ Create a new account</li>
                        </ul>
                    </div> }
                </div> }
            </div>
        );
    };

    return <div className='gac-project-content'>
        { getTopic() }
        <div className = 'gac-project-overview'>
            <div className="gac-overview-wrap">
                { getJobType() }
                { getProjectId() }
                { getFormatField() }
                { getStatus() }
                { getCreatedBy() }
                { getOrderedOn() }
                {/* getWrittenBy() */}
                {/* getPlannedPublish() */}
                { getTalentLogo() }
                { getTags() }
            </div>
        </div>
        { getBtns() }
    </div> ;
};