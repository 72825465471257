// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getMaintenanceMode, version} from '../../../../helpers/api';
import { uiActions } from "../../actions";

export function* callGetVersionWorker () {
    try {
        const response = yield call(fetch, `${api}/v1/version`, {
            method: 'GET',
        });
        const { response: { app_version, is_deploy }, error: {message}} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( version !== app_version ) {
            window.location.reload();
        }
        const isMaintenance = yield select(getMaintenanceMode);
        if ( isMaintenance !== is_deploy ) {
            yield put(uiActions.setUiState('isMaintenance', is_deploy));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
    }
}
