// Components
import { SeoAuditTechnical } from "./SeoAuditTechnical";
import { SeoAuditCompetitor } from "./SeoAuditCompetitor";
import { SeoAuditFull } from "./SeoAuditFull";

// Hooks
import { useOrder } from "../../../hooks/useOrder";

export const SeoAudit = ({ fields }) => {
    /* Hooks */
    const { getSeoAuditTypeField } = fields;
    const { seoaudit_type } = useOrder();

    const content = {
        technical: <SeoAuditTechnical fields = { fields } />,
        competitor: <SeoAuditCompetitor fields = { fields } />,
        full: <SeoAuditFull fields = { fields } />,
    };

    return <>
        { getSeoAuditTypeField() }
        { content[seoaudit_type] }
    </>;
};