// Core
import { put } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

// Instruments
import {getPort, getProtocol, isWhiteLabel} from '../../../../helpers/helpers';
import { authActions } from '../../../auth/actions';
import { uiActions } from '../../actions';
import {includes, isNil} from 'ramda';
import {api, fullDomain, primaryDomain} from "../../../../helpers/api";

export function* callInitializeWorker ({ payload: keys }) {
    const cookies = new Cookies();
    let privateKey = cookies.get('_gac_prk');
    let publicKey = cookies.get('_gac_pbk');
    let accountId = cookies.get('_gac_acc');
    let sessid = cookies.get('GACSESSID');

    console.debug(`Domain: ${fullDomain}`);
    console.debug(`Primary domain: ${primaryDomain}`);
    console.debug(`Protocol: ${getProtocol()}`);
    console.debug(`Port: ${getPort()}`);
    console.debug(`API Url: ${api}`);

    if ( isNil(privateKey) || isNil(publicKey) || isNil(accountId) ) {
        const keysArr = keys.split('&');
        keysArr.forEach(key => {
            const item = key.split('=');
            if ( item[0] === 'privateKey' ) {
                privateKey = item[1];
            } else if ( item[0] === 'publicKey' ) {
                publicKey = item[1];
            } else if ( item[0] === 'accountId' ) {
                accountId = item[1];
            } else if ( item[0] === 'sessid' ) {
                sessid = item[1];
            }
        });
    }

    if (privateKey && publicKey && accountId && sessid) {
        if ( isWhiteLabel() && !isNil(keys) && includes('privateKey', keys) ) {
            const cookies = new Cookies();
            const expires = new Date();
            expires.setSeconds(expires.getSeconds() + (3600*24*365));

            let options = {
                path: '/',
                expires,
                domain: window.location.hostname,
            };
            cookies.set('_gac_prk', privateKey, options);
            cookies.set('_gac_pbk', publicKey, options);
            cookies.set('_gac_acc', accountId, options);
            cookies.set('GACSESSID', sessid, options);
        }
        yield put(authActions.authenticate({
            accountId,
            privateKey,
            publicKey,
            sessid
        }));

        return null;
    } else {
        yield put(authActions.authenticateFail());
    }

    yield put(uiActions.initializeSuccess());
}
