// Core
import { useLayoutEffect } from "react";

// Hooks
import { useUser } from "../../../hooks/useUser";
import { useForms } from "../../../hooks/useForms";
import { useOrder } from "../../../hooks/useOrder";

// Instruments
import { getOrderForm, initDefaultRedactor } from "../../../helpers/helpers";
import { isEmpty, isNil } from "ramda";

export const SeoAuditTechnical = ({ fields }) => {
    /* Hooks */
    const {
        getSeoAuditLevelField,
        getSeoAuditWebsiteField,
        getSeoAuditedField,
        getTextareaField,
        getTargetCountryField,
        getGoogleAccessField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;
    const { details: { country }} = useUser();
    const forms = useForms();
    const { updateFormState } = forms;
    const { job_type, job_pay_type, topic_ideas_type, seoaudit_type } = useOrder();

    const { target_country_code } = getOrderForm(job_type, topic_ideas_type, seoaudit_type, job_pay_type, forms);

    useLayoutEffect(() => {
        if ( isEmpty(target_country_code) ) {
            updateFormState('seoauditTechnicalForm', { target_country_code: isEmpty(country) || isNil(country) ? 'US' : country });
        }

        initDefaultRedactor('#focus_areas-textarea', 'focus_areas', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#monitoring_tools-textarea', 'monitoring_tools', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#competitors-textarea', 'competitors', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#target_audience-textarea', 'target_audience', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#goals-textarea', 'goals', '116px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#focus_areas-textarea', 'destroy');
            window.$R('#monitoring_tools-textarea', 'destroy');
            window.$R('#competitors-textarea', 'destroy');
            window.$R('#target_audience-textarea', 'destroy');
            window.$R('#goals-textarea', 'destroy');
        };
    }, []);

    return <>
        { getSeoAuditLevelField() }
        { getSeoAuditWebsiteField() }
        { getSeoAuditedField() }
        { getTextareaField('focus_areas', 'Specific areas of your website you want us to focus on') }
        { getTextareaField('monitoring_tools', `What tools do you use to monitor your site's performance?`) }
        { getTextareaField('competitors', 'List your 3 main competitors') }
        { getTextareaField('target_audience', 'Describe the target audience of your website') }
        { getTargetCountryField() }
        { getTextareaField('goals', 'Main goals you want to achieve with this audit') }
        { getGoogleAccessField() }
    </>;
};