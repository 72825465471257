// Core
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { DayPicker } from 'react-day-picker';
import Creatable from 'react-select/creatable';
import Hint from "../common/Hint";
import Select from '../common/Select';

// Icons
import Plus from '../icons/Plus';
import PlusGrey from '../icons/PlusGrey';
import Minus from '../icons/Minus';
import MinusGrey from '../icons/MinusGrey';
import WordCount from '../icons/WordCount';
import Radio from '../icons/Radio';
import CheckedRadio from '../icons/CheckedRadio';
import { JobTypeIcon } from "../icons/JobTypeIcon";

// Hooks
import { useDropzoneEl } from "../../hooks/custom/useDropzoneEl";
import { useInvalidFields } from "../../hooks/custom/useInvalidFields";
import { useAuth } from "../../hooks/useAuth";
import { useForms } from "../../hooks/useForms";
import { useLists } from "../../hooks/useLists";
import { useOrder } from "../../hooks/useOrder";
import { useUi } from "../../hooks/useUi";
import { useUser } from "../../hooks/useUser";
import { useProject } from "../../hooks/useProject";
import { useModals } from "../../hooks/useModals";

// Instruments
import { currencyList, getOrderStateConfig, jobTypesArr, priorities } from "../../helpers/constants";

import {
    clearBrief,
    getActiveDesignSub,
    getCurrencyCode,
    getCurrencySign,
    getDesignFormat,
    getDesignFormatServiceLevel,
    getDimensions,
    getFormat,
    getLocalization,
    getOrderForm,
    getShortUnit,
    getTitle,
    getWordcount,
    isActiveDesignSub,
    isEpiic,
    isFetching,
    isSelfClientFn,
    isStrict,
    onOutsideElClick,
    removeScriptTags,
    round5,
    toLocaleString,
    upperCaseFirstLetter,
    DECIMAL_REGEX,
    isActiveWebBuildingSub,
    getOrdinal,
} from "../../helpers/helpers";
import { isEmpty, isNil, includes, uniq, reverse } from "ramda";
import { fields } from '../../helpers/required';
import { orderSchema as schema, orderDraftSchema } from "../../helpers/schemas";
import moment from 'moment/moment';
import update from 'immutability-helper';
import animateScrollTo from "animated-scroll-to";
import { object } from "yup";

const Url = require('url-parse');
const maxSize = 50000000; // = 50Mb = 50000000 bytes
const maxSize1Gb = 1080000000; // = 1Gb = 1080000000 bytes

export const useOrderForm = (page) => {
    /* Ref */
    const topicArrRef = useRef(null);
    const wordCountRef = useRef(null);
    const ideaCountRef = useRef(null);
    const writersListRef = useRef(null);
    const noWritersHintRef = useRef(null);
    const turnaroundHintRef = useRef(null);
    const turnaroundCustomRef = useRef(null);

    /* State */
    const [isSameTopics, setIsSameTopics] = useState(false);
    const [isWordCountValid, setIsWordCountValid] = useState(true);
    const [isBriefLimit, setIsBriefLimit] = useState(false);
    const [isTurnaroundHint, setIsTurnaroundHint] = useState(false);
    const [dayPicker, setDayPicker] = useState('');
    const [isDetails, setIsDetails] = useState(false);
    const [isAllTopicsOneDay, setIsAllTopicsOneDay] = useState(false);

    /* Hooks */
    const { invalidFields, removeField, addField, setInvalidFields } = useInvalidFields();
    const { keys: { accountId } } = useAuth();
    const forms = useForms();
    const { updateFormState, updateFormFilesState } = forms;
    const { orderList, existingIdeas, staticData: { unit_dimensions, countries, cms: cmsData, formats, industries, suggested, rates: ratesData, turnaround, localizations, copy_styles, copy_voices, content_types, target_audiences, file_types } } = useLists();
    const { tags, checkTopicAsync, createTagAsync, confirmProjectAsync, updateProjectAsync, createProjectAsync } = useProject();
    const { job_group, job_type, job_pay_type, orderCurrency, topic_ideas_type, seoaudit_type, editedProject, setOrderState, updateOrderState } = useOrder();
    const { activeModal, inviteTalentsObj, setModal, setModalState } = useModals();
    const { isAuth, isMobile, isTopicUnique, isEmptyWriters, brief_links_shared, fetching, projectsDesignTotal, isEmailExist, setUiState, checkBriefAsync } = useUi(); // isCheckedEmailProposal, isProposalCompanyUpdated,
    const {
        balance: { words_balance, type },
        brandProfiles,
        company: { branding: { host }, subscriptions },
        details: { currency, user_role },
        ipDetails,
        proposal,
        setUserState,
        fetchTalentsAsync,
        checkEmailExistAsync,
        // updateProposalCompanyAsync,
        sendProspectAsync
    } = useUser();
    const navigate = useNavigate();
    const form = getOrderForm(job_type, topic_ideas_type, seoaudit_type, job_pay_type, forms);
    const {
        attachments,
        avoid,
        brief,
        content,
        content_goal,
        copy_style,
        copy_voice,
        file_types: formFileTypes,
        files,
        format,
        idea_count,
        idea_count_type,
        industry,
        invite_writer_active_for,
        invite_writers,
        level,
        localization,
        mention,
        order_on,
        other_industry,
        product_list,
        project_tags,
        quantity,
        similar_content,
        smart_match,
        target_audience,
        target_country_code,
        topic,
        turnaround_custom,
        turnaround_type,
        word_count,
        word_count_type,
        written_by,
        dimensions,
        image_option,
        image_option_files,
        priority,
        brand_profiles,
        activeIdea,
        logo_file,
        length: animationLength,
        format_options,
        website,
        cms,
        google_analytics,
        google_search_console,
        link_to_resources,
        resources,
        seo_audited,
        google_access,
        link_analized,
        idea_files,
        idea_attachments,
        workflow,
        sitemap,
        used_services,
        review_frequency
    } = form;

    const getFormLabel = (value) => {
        const str = isNil(value) ? job_type : value;
        return includes(str, 'writing/design/motion/blogging') && job_pay_type === 'subscription'
            ? str === 'blogging'
                ? `${str}${upperCaseFirstLetter(topic_ideas_type)}Form`
                : `${str}SubscriptionForm`
            : str === 'seoaudit'
                ? `${str}${upperCaseFirstLetter(seoaudit_type)}Form`
                : `${str}Form`;
    };

    const onUpdateFiles = (prop, value, id) => {
        updateFormFilesState(getFormLabel(), prop, value, id);
    };
    const onRemoveFile = (prop, value) => {
        updateFormState(getFormLabel(), { [prop]: value });
    };
    const { progress, onFormDrop, onFormDragStart, getDropzoneHtml } = useDropzoneEl(attachments, files, onUpdateFiles, onRemoveFile, job_type === 'design' ? maxSize1Gb : maxSize, invalidFields, removeField, job_type, job_group, 'files', 'attachments', true);
    const { progress: motionProgress, getDropzoneMotion } = useDropzoneEl(attachments, logo_file, onUpdateFiles, onRemoveFile, maxSize1Gb, invalidFields, removeField, job_type, job_group, 'logo_file', 'attachments');
    const { progress: motionIdeasProgress, getDropzoneHtml: getMotionIdeasDropzoneHtml } = useDropzoneEl(idea_attachments, idea_files, onUpdateFiles, onRemoveFile, maxSize1Gb, invalidFields, removeField, job_type, job_group, 'idea_files', 'idea_attachments', true);
    const { search } = useLocation();

    const getTurnaround = () => {
        const levelValue = level ? level : 'pro';
        const count = word_count * quantity;
        const wordCountValue = count < 1200 ? 0 : count > 1199 && count < 3500 ? 1200 : 3500;
        let predefined = {};

        if (job_type === 'blogging' && topic_ideas_type === 'request') {
            predefined = turnaround['ideas'][0]
        } else if (includes(job_type, 'design/motion/webaudit/seoaudit')) {

        } else if (job_type === 'keywords' || job_type === 'ideas') {
            predefined = turnaround[job_type][0]
        } else if (!isNil(job_type)) {

            predefined = turnaround[includes(job_type, 'aih/blogging') ? 'writing' : job_type]
                .filter((item) => item.smart_match === smart_match)
                .filter((item) => item.level.includes(levelValue))
                .filter((item) => item.word_count === wordCountValue)[0];
        }
        const { def_written_by = 0, min_written_by = 0, planned_publish = 0 } = predefined || {};

        return { def_written_by, min_written_by, planned_publish };
    };
    const getDefaultsDates = () => {
        const { def_written_by } = getTurnaround();

        const minute = moment().minute();
        const orderOnValue = moment().utc().minutes(round5(minute));
        const writtenByValue = moment(orderOnValue).utc().add(def_written_by, 'hours');

        return {
            order_on: [orderOnValue.format('YYYY-MM-DD HH:mm:ss')],
            written_by: [writtenByValue.format('YYYY-MM-DD HH:mm:ss')],
        };
    };
    const getExistingIdeaObj = (id) => {
        const activeId = isNil(id) ? existingIdeas[0].ideas[0].id : id;
        let ideasArr = [];
        existingIdeas.forEach(({ ideas }) => {
            ideasArr.push(...ideas);
        });
        const idea = ideasArr.filter(o => `${o.id}` === `${activeId}`)[0];
        const data = existingIdeas.filter(o => o.ideas.some(a => `${a.id}` === `${activeId}`))[0];
        const { description, keywords, title, files, idea_number } = idea;
        const { topic: website, product_service, target_audience, content_goal, brief: briefData, format, industry, other_industry, level, smart_match, avoid, localization, word_count, writer_uid, project_id } = data;
        const filesData = isNil(files) ? [] : files.map(o => ({ ...o, name: o.filename }));
        const brief = `<p>Description:</p>
        ${description}
        <p></p>
        <p>Keywords:</p>
        <p>${keywords}</p>
        <p></p>
        <p>Website:</p>
        <p>${website}</p>
        <p></p>
        <p>Target product/service or keywords:</p>
        ${product_service}
        <p></p>
        <p>Target audience:</p>
        <p>${target_audience}</p>
        <p></p>
        <p>Content goal:</p>
        <p>${content_goal}</p>
        <p></p>
        <p>Brief:</p>
        ${briefData}`;
        const obj = {
            activeIdea: activeId,
            format,
            industry,
            other_industry,
            level,
            word_count: word_count > words_balance ? !!words_balance ? words_balance : 100 : word_count,
            topic: [clearBrief(title)],
            smart_match,
            avoid,
            localization,
            brief,
            idea_number,
            idea_project_id: project_id,
            attachments: filesData,
            files: filesData,
            invite_writer_active_for: 168,
            invite_writers: [{ writer_uid }],
            target_audience: 'general',
            content: 'text',
            copy_style: 'copywriter',
            copy_voice: 'copywriter',
            order_on: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            written_by: moment().utc().add(1, 'week').format('YYYY-MM-DD HH:mm:ss'),
        };

        return obj;
    };
    const parseUrl = () => {
        const str = search.substring(1).split('&')[0];
        const { query } = new Url(window.location.href, true);
        const obj = {};

        Object.keys(query).forEach((key) => {
            if (key === 'format') {
                if (str === 'writing' && formats['writing'].some(o => o.value === query[key])) {
                    obj[key] = query[key];
                } else if (str === 'design' && formats['design'].filter(o => !!o.active).some(o => o.formats.some(a => `${a.id}` === `${query[key]}`))) {
                    obj[key] = query[key];
                } else if (str === 'motion' && formats['motion'].filter(o => !!o.active).some(o => o.formats.some(a => `${a.id}` === `${query[key]}`))) {
                    obj[key] = query[key];
                }
                // /order/new?design&format=96&option=147
                // /order/new?motion&format=169&option=303
            }
            if (key === 'option') {
                const optionsObj = formats[str].filter(o => !!o.active).filter(o => o.formats.some(a => `${a.id}` === `${query['format']}`))[0].formats.filter(a => `${a.id}` === `${query['format']}`)[0].options.filter(a => `${a.id}` === `${query[key]}`)[0];
                if (!isEmpty(optionsObj) && !isNil(optionsObj)) {
                    obj['format_options'] = [{ id: `${optionsObj.id}`, amount: '', custom: !!Number(optionsObj.custom) }];
                }
            }

            if (key === 'level' && 'standard/pro/expert'.includes(query[key])) {
                obj[key] = query[key];
            }
            if (key === 'word_count') {
                const wordCounts = getSuggestedWordCount();
                obj['word_count'] = query[key];
                obj['word_count_type'] = wordCounts.includes(`${query[key]}`) ? `${query[key]}` : 'custom';
            }
            if (key === 'words') {
                obj['word_count_type'] = 'custom';
                obj['word_count'] = query[key];
            }
            if (key === 'count') {
                obj['idea_count_type'] = 'custom';
                obj['idea_count'] = query[key];
            }
            if (key === 'writer') {
                // TODO actions.fetchCopywritersList(query[key]);
            }
            if (key === 'planned-word-count') {
                obj['word_count'] = query[key];
            }
            if (key === 'email' && !isAuth) {
                checkEmailExistAsync(decodeURIComponent(query[key]));
            }
            if (key === 'wantTopic' && !isAuth) {
                obj['smart_match'] = query[key] === '1' ? 'no' : 'yes';
            }
        });

        return obj;
    };

    /* Actions */
    const onJobGroupChange = ({ currentTarget: { dataset: { value } } }) => {
        if (page === 'editing' || isStrict(search)) return false;
        if (job_group !== value) {
            setInvalidFields([]);
            updateOrderState({ job_group: value, job_type: null, job_pay_type: null });
        }
    };
    const onJobTypeChange = ({ currentTarget: { dataset: { value } } }) => {
        if (page === 'editing' || isStrict(search)) return false;
        if (job_type !== value) {
            setInvalidFields([]);
            updateOrderState({ job_type: value, job_pay_type: isAuth && isSelfClientFn(user_role) ? 'subscription' : null });
        }
    };
    const onJobPayTypeChange = ({ currentTarget: { dataset: { value } } }) => {
        if (page === 'editing' || isStrict(search)) return false;
        if (job_pay_type !== value) {
            setInvalidFields([]);
            setOrderState('job_pay_type', value);
        }
    };
    const onSeoAuditTypeChange = ({ currentTarget: { dataset: { value } } }) => {
        if (page === 'editing') return false;
        if (seoaudit_type !== value) {
            setInvalidFields([]);
            setOrderState('seoaudit_type', value);
        }
    };
    const onOrderCurrencyChange = (_, value) => {
        if (orderCurrency !== value) {
            setOrderState('orderCurrency', value);
        }
    };
    const onTopicIdeasTypeChange = ({ currentTarget: { dataset: { value } } }) => {
        if (page === 'editing') return false;
        if (topic_ideas_type !== value) {
            setInvalidFields([]);
            setOrderState('topic_ideas_type', value);
        }
    };
    const onLevelChange = ({ currentTarget: { dataset: { prop, value } } }) => {
        // if ( prop === 'level' && isActiveSub && form.job_type === 'ideas' ) {
        //     return false;
        // }
        // if ( prop === 'level' && value === 'standard' && form.turnaround_type === 'expedited' ) {
        //     obj['turnaround_type'] = 'regular';
        // }
        if (level !== value) {
            removeField('level');
            updateFormState(getFormLabel(), { [prop]: value });
        }
    };
    const onSmartMatchChange = ({ currentTarget: { dataset: { value } } }) => {
        if (smart_match !== value) updateFormState(getFormLabel(), { 'smart_match': value });
    };
    const onTurnaroundTypeChange = ({ currentTarget: { dataset: { prop, value, writtenByHours } } }) => {
        if (turnaround_type !== value) {
            const writtenByValue = order_on.map(el => moment(el).add(writtenByHours, 'hours').format('YYYY-MM-DD HH:mm:ss'));

            updateFormState(getFormLabel(), { 'written_by': writtenByValue, [prop]: value });
        }
    };
    const onTurnaroundCustomClick = (e) => {
        e.stopPropagation(); //TODO onTurnaroundCustomClick
        updateFormState(getFormLabel(), { 'turnaround_type': 'custom', 'turnaround_custom': '' });
    };
    const onTurnaroundCustomBlur = ({ currentTarget: { dataset: { min } } }) => {
        const num = Number(min);
        const custom = turnaround_custom < num ? num : turnaround_custom;
        const writtenByValue = order_on.map(el => moment(el).add(custom * 24, 'hours').format('YYYY-MM-DD HH:mm:ss'));

        updateFormState(getFormLabel(), { 'turnaround_custom': custom, 'written_by': writtenByValue });
    };
    const onTopicFocusOut = () => {
        let prop = 'topic';
        let value = topic.map(el => removeScriptTags(el));

        if (format === 'product') {
            prop = 'product_list';
            value = product_list.map(el => removeScriptTags(el));
        }

        updateFormState(getFormLabel(), { [prop]: value });

        if (isAuth && quantity === 1 && format !== 'product') {
            if (value[0].length > 1 && isTopicUnique) {
                checkTopicAsync(value[0]);
            }
        }
    };
    const onFormFieldChange = (prop, value) => {
        const data = {};

        if (value) {
            removeField(prop);
        }
        if (prop === 'format' && job_type !== 'ideas') {
            data.word_count_type = word_count_type !== 'custom' ? '' : word_count_type;
            data.word_count = word_count_type !== 'custom' ? quantity > 1 ? Array(quantity).fill(0) : 0 : word_count;
            if (isEpiic(host) && job_type === 'writing') {
                data.word_count = word_count;
            }
            if (page === 'editing' && value !== 'product') {
                data.quantity = 1;
            }
        }
        if (prop === 'format' && job_type === 'design') {
            if (value !== '42' && includes('ppt', formFileTypes)) {
                data.file_types = formFileTypes.filter(item => item !== 'ppt');
            }
            data.dimensions = [];
        }
        if (prop === 'format' && includes(job_type, 'design/motion') && job_pay_type === 'one-off') {
            data.format_options = [];
            const optionsArr = formats[job_type].filter(o => o.formats.some(a => `${a.id}` === `${value}`))[0].formats.filter(a => `${a.id}` === `${value}`)[0].options;
            if (optionsArr.length === 1) {
                data.format_options = [{ id: `${optionsArr[0].id}`, amount: '', custom: !!Number(optionsArr[0].custom) }];
            }
        }
        if (prop === 'target_country_code') {
            data.target_country_code = value.toUpperCase();
        }

        updateFormState(getFormLabel(), { [prop]: value, ...data });
    };
    const onInputFieldChange = (e) => {
        e.persist();
        const { currentTarget: { id: prop, value } } = e;

        if (value) {
            removeField(prop);
        }
        if (prop === 'topic_array') {
            setIsSameTopics(false);
        }
        if ((prop === 'topic' || prop === 'topic_array') && !isTopicUnique) {
            setUiState('isTopicUnique', true);
        }
        if (prop === 'word_count') {
            const num = Number(value.replace(/[^0-9]/gi, ''));
            const word_count =
                (job_type === 'blogging' || job_type === 'writing') && words_balance && job_pay_type === 'subscription'
                    ? num > words_balance
                        ? words_balance
                        : num
                    : job_type === 'aih'
                        ? num > 4000
                            ? 4000
                            : num
                        : num > 99999
                            ? 99999
                            : num;

            updateFormState(getFormLabel(), { [prop]: quantity > 1 ? Array(quantity).fill(word_count) : word_count });
            return;
        }
        if (prop === 'idea_count') {
            const num = Number(value.replace(/[^0-9]/gi, ''));

            updateFormState(getFormLabel(), { [prop]: num > 99999 ? 99999 : num });
            return;
        }
        if (prop === 'topic_array') {
            let topic_array = value.replace(/ +(?= )/g, '').split('\n').filter((item, i) => i < quantity);
            const caretStart = topicArrRef.current.selectionStart;
            const caretEnd = topicArrRef.current.selectionEnd;

            updateFormState(getFormLabel(), { 'topic': topic_array });
            topicArrRef.current.setSelectionRange(caretStart, caretEnd);
            return;
        }
        if (prop === 'topic') {
            updateFormState(getFormLabel(), { 'topic': [`${value.replace(/ +(?= )/g, '')}`] });
            return;
        }
        if (prop === 'product_list_array') {
            updateFormState(getFormLabel(), { 'product_list': value.split('\n').filter((item, i) => i < quantity) });
            return;
        }
        if (prop === 'product_list') {
            updateFormState(getFormLabel(), { 'product_list': [value] });
            return;
        }
        if (prop === 'turnaround_custom') {
            const num = Number(value.replace(/[^0-9]/gi, ''));

            updateFormState(getFormLabel(), { [prop]: num > 365 ? 365 : num });
            return;
        }

        updateFormState(getFormLabel(), { [prop]: value });
    };
    const onOrderOnInputClick = ({ currentTarget: { dataset: { i } } }) => {
        setDayPicker(i);
    };
    const onOrderOnChange = (day) => {
        let date = day;
        const hour = moment().hour();
        const minute = moment().minute();
        date = moment(day).hours(hour).minutes(minute);

        const hours = moment.duration(moment(written_by[0]).diff(moment(order_on[0]))).asHours();

        // planned_publish: moment(written_by).add(def_planned_publish, 'hours').format('YYYY-MM-DD HH:mm:ss'),
        // else if (prop === 'written_by') {
        //     dates = {
        //         planned_publish: moment(day).add(def_planned_publish, 'hours').format('YYYY-MM-DD HH:mm:ss'),
        //     };
        // }
        // if (prop === 'planned_publish') {
        //     const { form: { planned_publish }} = this.props;
        //     const h = moment(planned_publish).hour();
        //     const m = moment(planned_publish).minute();
        //     date = moment(day).hours(h).minutes(m);
        // }

        const orderOnValue = [...order_on];
        const writtenByValue = [...written_by];
        orderOnValue.splice(Number(dayPicker), 1, moment(date).utc().format('YYYY-MM-DD HH:mm:ss'));
        writtenByValue.splice(Number(dayPicker), 1, moment(date).utc().add(hours, 'hours').format('YYYY-MM-DD HH:mm:ss')); //

        updateFormState(getFormLabel(), {
            'written_by': writtenByValue,
            'order_on': orderOnValue,
        });
        setDayPicker('');
    };
    const toggleDetails = () => {
        setIsDetails(state => !state);
    };
    const onIsAllTopicsOneDayChange = () => {
        setIsAllTopicsOneDay(state => !state);
    };
    /* Redactor */
    const onRedactorSynced = (data, prop) => {
        let obj = {};
        if (prop === 'brief') {
            setUiState('brief_links_shared', 'yes');
            setIsBriefLimit(clearBrief(data).length > 30000);
        }
        if (prop === 'content') {
            obj = {
                word_count_type: 'custom',
                word_count: getWordcount(data),
            };
        }
        updateFormState(getFormLabel(), { ...obj, [prop]: data });
        removeField(prop);
    };
    const onRedactorBlur = (prop) => {
        if (prop === 'brief') {
            if (!isEmpty(clearBrief(brief)) && brief_links_shared === 'yes') {
                checkBriefAsync(brief);
            }
        }
    };
    /* WordCount */
    const onWordCountKeyDown = (e) => {
        const { keyCode } = e;

        if (keyCode === 40 || keyCode === 38) {
            e.preventDefault();
        }
    };
    const onWordCountKeyUp = ({ keyCode, currentTarget: { value, dataset: { i } } }) => {
        let num = Number(value.replace(/[^0-9]/gi, ''));
        let j = Number(i);

        if ((keyCode === 13 || keyCode === 40) && j + 1 < quantity) {
            document.getElementById(`input-${j + 1}`).focus();
        }
        if (keyCode === 8 && j > 0 && num === 0) {
            document.getElementById(`input-${j - 1}`).focus();
        }
        if (keyCode === 38 && j > 0) {
            document.getElementById(`input-${j - 1}`).focus();
        }
    };
    const onWordCountArrayChange = ({ currentTarget: { value, dataset: { i } } }) => {
        const wordCountValue = [...word_count];
        let num = Number(value.replace(/[^0-9]/gi, ''));
        num = num < 1 ? 0 : num > 99999 ? 99999 : num;
        wordCountValue.splice(i, 1, num);

        if (value && invalidFields.includes('word_count_array')) {
            removeField('word_count_array');
        }

        updateFormState(getFormLabel(), { 'word_count': wordCountValue });
    };
    const onWordCountChange = ({ currentTarget: { dataset: { prop, value } } }) => {
        const count = value === 'custom' ? 0 : Number(value);
        let data = { 'word_count_type': value, 'word_count': quantity > 1 ? Array(quantity).fill(count) : count };

        if (prop === 'idea_count_type') data = { 'idea_count_type': value, 'idea_count': count };

        removeField(prop);
        updateFormState(getFormLabel(), data);
        if (value === 'custom') {
            if (prop === 'idea_count_type') {
                ideaCountRef.current.focus();
            } else {
                wordCountRef.current.focus();
            }
        } else {
            setIsWordCountValid(true);
        }
    };
    const onWordCountInputBlur = ({ currentTarget: { value, id } }) => {
        const num = Number(value.replace(/[^0-9]/gi, ''));
        const wordCountValue = job_type === 'blogging' || (job_type === 'writing' && isEpiic(host)) ? num < 100 ? 100 : num : num < 10 ? 10 : num;
        const ideaCountValue = num < 4 ? 4 : num;

        document.getElementById(id).placeholder = 'Custom';
        updateFormState(getFormLabel(), { [id]: id === 'idea_count' ? ideaCountValue : quantity > 1 ? Array(quantity).fill(wordCountValue) : wordCountValue });
    };
    const onWordCountInputFocus = ({ currentTarget: { id } }) => {
        document.getElementById(id).placeholder = '';
    };
    /* Qty */
    const onQtyChange = ({ currentTarget: { id: prop, value } }) => {
        const el = word_count_type ? Number(word_count_type === 'custom' ? Array.isArray(word_count) ? word_count[0] : word_count : word_count_type) : Array.isArray(word_count) ? word_count[0] : 0;
        let wordCountValue;
        let topicValue = [''];
        let productListValue = [''];
        const quantityValue = Number(value) < 1 ? 1 : Number(value) > 999 ? 999 : Number(value);

        if (quantityValue === 1) {
            wordCountValue = el;
        } else if (quantityValue < word_count.length) {
            wordCountValue = word_count.filter((item, i) => i < quantityValue);
            topicValue = topic.filter((item, i) => i < quantityValue);
            productListValue = product_list.filter((item, i) => i < quantityValue);
        } else {
            wordCountValue = Array.isArray(word_count) ? [...word_count, ...Array(quantityValue - word_count.length).fill(el)] : Array(quantityValue).fill(el);
            // topicValue = [ ...topic, '' ];
            // productListValue = [ ...product_list, '' ];
        }

        updateFormState(getFormLabel(), { 'quantity': quantityValue, 'word_count': wordCountValue, 'topic': topicValue, 'product_list': productListValue });
    };
    const decreaseQty = () => {
        if (quantity !== 1) {
            const wordCountValue =
                quantity === 2
                    ? word_count_type
                        ? Number(word_count_type === 'custom' ? Array.isArray(word_count) ? word_count[0] : word_count : word_count_type)
                        : 0
                    : word_count.filter((item, i) => i < quantity - 1);
            const orderOnValue = order_on.filter((item, i) => i < quantity - 1);
            const writtenByValue = written_by.filter((item, i) => i < quantity - 1);
            const topicValue = topic.filter((item, i) => i < quantity - 1);
            const productListValue = product_list.filter((item, i) => i < quantity - 1);

            updateFormState(getFormLabel(), { 'quantity': quantity - 1, 'word_count': wordCountValue, 'topic': topicValue, 'product_list': productListValue, 'order_on': orderOnValue, 'written_by': writtenByValue });
        }
    };
    const increaseQty = () => {
        if (quantity !== 999) {
            const el = word_count_type ? Number(word_count_type === 'custom' ? Array.isArray(word_count) ? word_count[0] : word_count : word_count_type) : Array.isArray(word_count) ? word_count[0] : 0;
            const wordCountValue = quantity === 1 ? [word_count, el] : [...word_count, el];
            const orderOnValue = [...order_on, ''];

            updateFormState(getFormLabel(), { 'quantity': quantity + 1, 'word_count': wordCountValue, 'order_on': orderOnValue });
        }
    };
    /* Writers */
    const onMouseOver = () => {
        const list = writersListRef.current;
        const rect = list.getBoundingClientRect();
        if (rect.top < 112) {
            const offset = list.dataset.offset;
            list.style.top = 'auto';
            list.style.bottom = offset;
            list.classList.add('bottom');
        }
    };
    const onMouseOut = () => {
        const list = writersListRef.current;
        const offset = list.dataset.offset;
        list.style.bottom = 'auto';
        list.style.top = offset;
        list.classList.remove('bottom');
    };
    const generateWriters = () => {
        const data = invite_writers ? invite_writers : [];
        const writers = invite_writers.map(({ writer_uid, writer_avatar_url = '', writer_first_name, writer_last_name }) => {
            return <div key={writer_uid} className={`gac-writer-item ${writer_avatar_url ? 'gac-with-image' : 'gac-active'}`}>
                {writer_avatar_url
                    ? <img src={writer_avatar_url} alt={`${writer_first_name.charAt(0)}${writer_last_name} avatar`} />
                    : `${writer_first_name.charAt(0)}${writer_last_name}`}
                <div className="gac-writer-name">
                    {writer_first_name} {writer_last_name}
                </div>
            </div>;
        });
        const writersFirst = writers.filter((o, i) => i <= 4);
        const rest = data.filter((item, i) => i > 4 && i < 15).map((item, i) => <div key={i}>{`${item.writer_first_name} ${item.writer_last_name}`}</div>);

        return <>
            {writers.length
                ? writers.length > 5
                    ? <>
                        {writersFirst}
                        <div className='gac-writers-rest' onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                            {`+${writers.length - 5}`}
                            <div data-offset={`-${40 + 22 * (rest.length - (writers.length > 15 ? 0 : 1))}px`} className="gac-writers-rest-list" ref={writersListRef} style={{ top: `-${40 + 22 * (rest.length - (writers.length > 15 ? 0 : 1))}px` }}>
                                {rest}
                                {writers.length > 15 && <div>{`+ ${writers.length - 15} more`}</div>}
                            </div>
                        </div>
                    </>
                    : writers
                : <><div className='gac-writer-item' /><div className='gac-writer-item' /><div className='gac-writer-item' /></>
            }
            {(!isAuth && data.length) ? null : <span className='gac-writers-plus'>+</span>}
        </>;
    };
    const showModalWriters = () => {
        fetchTalentsAsync({ type: includes(job_type, 'design/motion') ? 'designers' : 'copywriters', page: 'order' });
        setModalState('modalTalentsData', { invite_writers, invite_writer_active_for, level, job_type });
    };
    const inviteAllWriters = () => {
        updateFormState(getFormLabel(), { invite_writers: [], invite_writer_active_for: 0 });
    };
    // const showTurnaroundHint = () => {
    //     setIsTurnaroundHint(true);
    // };
    /* Tags */
    const onTagsChange = (tag) => {
        updateFormState(getFormLabel(), { project_tags: tag });
    };
    const onTagsCreate = (tag) => {
        if (isFetching(fetching)) return;
        if (isAuth) {
            createTagAsync(tag);
        }
        const tags = isNil(project_tags) ? [] : project_tags;
        updateFormState(getFormLabel(), { project_tags: [...tags, { value: tag, label: tag }] });
    };
    const onTagsInputChange = (value) => {
        return value.replace(/[^0-9a-zA-Z_]/gi, '').substr(0, 25);
    };
    const formatCreateLabel = (value) => {
        return `Create '${value}'`;
    };
    const noTagsMessage = () => {
        return 'Type in a new tag';
    };
    /* Dimensions */
    const onDimensionsInputChange = ({ currentTarget: { value, dataset: { id, prop } } }) => {
        if (!DECIMAL_REGEX.test(value)) return;
        const index = dimensions.findIndex(o => o.id === id);
        const data = { id, [prop]: value };
        const dimensionsValue = index !== -1
            ? update(dimensions, {
                [index]: {
                    [prop]: { $set: value }
                },
            })
            : [...dimensions, data];

        updateFormState(getFormLabel(), { 'dimensions': dimensionsValue });
    };
    const onDimensionsUnitsChange = (prop, value, id) => {
        const index = dimensions.findIndex(o => o.id === id);
        const data = { id, [prop]: value };
        const dimensionsValue = index !== -1
            ? update(dimensions, {
                [index]: {
                    [prop]: { $set: value }
                },
            })
            : [...dimensions, data];

        updateFormState(getFormLabel(), { 'dimensions': dimensionsValue });
    };
    const onDimensionsSelect = ({ currentTarget: { dataset: { id } } }) => {
        if (id) {
            removeField('dimensions');
        }
        const value = { id };
        updateFormState(getFormLabel(), { 'dimensions': dimensions.some(o => o.id === id) ? dimensions.filter(o => o.id !== id) : [...dimensions, value] });
    };
    const onImageFilesClick = ({ currentTarget: { dataset: { id } } }) => {
        const data = image_option_files.filter(o => o.file_id !== id);
        updateFormState(getFormLabel(), { image_option_files: data, image_option: isEmpty(data) ? '' : image_option });
    };
    const onDesignImagesRadioClick = ({ currentTarget: { dataset: { value } } }) => {
        if (image_option !== value) {
            removeField('image_option');
            if (value === 'browse-images') {
                setModal('images');
            }
            updateFormState(getFormLabel(), { image_option: value, image_notes: '', image_option_files: [] });
        }
    };
    const onWorkflowRadioClick = ({ currentTarget: { dataset: { value } } }) => {
        updateFormState(getFormLabel(), { workflow: includes(value, workflow) ? workflow.filter(o => o !== value) : [...workflow, value] });
    };
    const onUsedServicesChange = ({ currentTarget: { dataset: { value } } }) => {
        removeField('file_types');
        updateFormState(getFormLabel(), {
            used_services: includes(value, used_services) ? used_services.filter(o => o !== value) : [...used_services, value],
            file_types: [],
        });
    };
    const onBrandProfileRadioClick = ({ currentTarget: { dataset: { value } } }) => {
        if (value === 'create-new-profile') {
            setModal('createBrandProfile');
            return;
        }
        const valueAsNumber = Number(value);
        if (includes(valueAsNumber, brand_profiles)) {
            updateFormState(getFormLabel(), { brand_profiles: brand_profiles.filter(o => o !== valueAsNumber) });
        } else {
            updateFormState(getFormLabel(), { brand_profiles: [...brand_profiles, valueAsNumber] });
        }
    };
    const onBrandProfileEdit = (e) => {
        e.stopPropagation();
        const { currentTarget: { dataset: { id } } } = e;

        setUserState('activeBrandProfile', brandProfiles.filter(o => `${o.id}` === `${id}`)[0]);
        setModal('createBrandProfile');
    };
    const onFileTypesChange = ({ currentTarget: { dataset: { prop, value } } }) => {
        const list = ['fig', 'ai', 'psd', 'indd', 'ppt', 'key', 'gslides', 'gif', 'mp4', 'mov', 'ae', 'webm', 'lottie'];
        let data = includes(value, formFileTypes) ? formFileTypes.filter(item => item !== value) : [...formFileTypes, value];

        if (value) {
            removeField(prop);
        }
        if (prop === 'file_types' && includes(value, list)) {
            const shortList = list.filter(item => item !== value);
            const shortData = formFileTypes.filter(item => !includes(item, shortList));
            data = includes(value, formFileTypes) ? formFileTypes.filter(item => item !== value) : [...shortData, value];
        }
        updateFormState(getFormLabel(), { [prop]: data });
    };
    const onExistingIdeaChange = ({ currentTarget: { value } }) => {
        if (isNil(activeIdea) || `${activeIdea}` === `${value}`) return null;

        const idea = getExistingIdeaObj(value);
        updateFormState('bloggingExistingForm', { ...idea, activeIdea: `${value}` });
    };
    const onFormatOptionChange = ({ currentTarget: { dataset: { id, custom } } }) => {
        if (id) {
            removeField('format_options');
        }
        updateFormState(getFormLabel(), { 'format_options': format_options.some(o => `${o.id}` === `${id}`) ? format_options.filter(o => `${o.id}` !== `${id}`) : [...format_options, { id, amount: '', custom: !!Number(custom) }] });
    };
    const onFormatOptionInputChange = ({ currentTarget: { value, dataset: { id } } }) => {
        if (value > 0 && value < 999) {
            removeField('format_options');
            updateFormState(getFormLabel(), { 'format_options': [...format_options.filter(o => `${o.id}` !== `${id}`), ...Array(Number(value)).fill({ id, amount: '', custom: false })] });
        }
    };
    const onFormatOptionInputClick = (e) => {
        e.stopPropagation();
    };
    const onFormatOptionIncrease = (e) => {
        e.stopPropagation();
        const { currentTarget: { dataset: { id, value } } } = e;

        if (value < 999) {
            if (id) {
                removeField('format_options');
            }
            updateFormState(getFormLabel(), { 'format_options': [...format_options.filter(o => `${o.id}` !== `${id}`), ...Array(Number(value)).fill({ id, amount: '', custom: false })] });
        }
    };
    const onFormatOptionDecrease = (e) => {
        e.stopPropagation();
        const { currentTarget: { dataset: { id, value } } } = e;

        if (value > 0) {
            if (id) {
                removeField('format_options');
            }
            updateFormState(getFormLabel(), { 'format_options': [...format_options.filter(o => `${o.id}` !== `${id}`), ...Array(Number(value)).fill({ id, amount: '', custom: false })] });
        } else if (Number(value) === 0) {
            if (id) {
                removeField('format_options');
            }
            updateFormState(getFormLabel(), { 'format_options': [...format_options.filter(o => `${o.id}` !== `${id}`)] });
        }
    };
    const onCustomQuoteInputChange = ({ currentTarget: { value, dataset: { id } } }) => {
        const num = Number(value.replace(/[^0-9]/gi, ''));
        if (num <= 99999) {
            removeField('format_options');
            removeField('custom_quote');
            updateFormState(getFormLabel(), { 'format_options': [...format_options.filter(o => `${o.id}` !== `${id}`), { id, amount: Number(num), custom: true }] });
        }
    };

    /* Submit */
    const validateFields = (action) => {
        let fields = [];
        let data = JSON.parse(JSON.stringify(form));

        if (action === 'save_draft' && data.hasOwnProperty('topic')) {
            try {
                orderDraftSchema.validateSync(data, { abortEarly: false });
            } catch (err) {
                fields = uniq(err.inner.map(o => o.params.label));
            }
        } else {
            data = {
                ...data,
                action,
                isAuth,
                isBriefLimit,
                isAllTopicsOneDay,
                words_balance,
                job_type,
                formats,
                progress: job_type === 'motion' ? [...motionProgress, ...motionIdeasProgress] : progress,
                brief: clearBrief(data.brief),
                design_references: clearBrief(data.design_references),
                image_notes: clearBrief(data.image_notes),
                competitors: clearBrief(data.competitors),
                product_service: clearBrief(data.product_service),
                other_industry: clearBrief(data.other_industry),
                content: getWordcount(data.content),
                company_details: clearBrief(data.company_details),
                brand_description: clearBrief(data.brand_description),
                ideas: clearBrief(data.ideas),
                examples: clearBrief(data.examples),
                logo_description: clearBrief(data.logo_description),
                format_description: clearBrief(data.format_description),
                format_target_audience: clearBrief(data.format_target_audience),
                format_reason: clearBrief(data.format_reason),
                products: clearBrief(data.products),
                goals: clearBrief(data.goals),
                target_audience: clearBrief(data.target_audience),
            };

            try {
                if (job_type === 'blogging' && job_pay_type === 'subscription') {
                    schema[job_type][topic_ideas_type].validateSync(data, { abortEarly: false });
                } else if (includes(job_type, 'seoaudit')) {
                    schema[job_type][seoaudit_type].validateSync(data, { abortEarly: false });
                } else if (includes(job_type, 'writing/design/motion/blogging')) {
                    schema[job_type][job_pay_type].validateSync(data, { abortEarly: false });
                } else {
                    schema[job_type].validateSync(data, { abortEarly: false });
                }
            } catch (err) {
                fields = uniq(err.inner.map(o => o.params.label));
            }
        }

        return fields;
    };
    const onSubmit = ({ currentTarget: { dataset: { action } } }) => {
        const project = form;
        const formFields = validateFields(action);

        setInvalidFields(formFields);

        if (isEmpty(formFields)) {
            let obj = {};
            let actionObj = { name: action, job_type };
            const files = project.files ? project.files.map(o => o.file_id) : [];
            const invite_writers = project.invite_writers ? project.invite_writers.map(({ writer_uid }) => writer_uid) : [];
            const project_tags = !isNil(project.project_tags) && !isEmpty(project.project_tags) ? project.project_tags.map(o => o.value) : [];
            if (isEmpty(invite_writers)) {
                delete project.invite_writer_active_for;
            }

            if (job_type === 'blogging') {
                const isRequestTI = topic_ideas_type === 'request';
                const { min_written_by } = getTurnaround();
                if (job_pay_type === 'one-off') {
                    const qty = project.quantity;
                    const topic = qty > 1 ? JSON.stringify(project.topic) : project.topic[0];
                    const word_count =
                        qty > 1
                            ? JSON.stringify(project.word_count)
                            : Array.isArray(project.word_count)
                                ? project.word_count[0]
                                : project.word_count;
                    obj = {
                        ...project,
                        type: 'marketplace',
                        job_type: 'writing',
                        invite_writers,
                        topic,
                        word_count,
                        project_tags,
                        talent: 'bloggers',
                        pay_type: job_pay_type,
                        order_on: project.quantity > 1 ? JSON.stringify(isAllTopicsOneDay ? Array(project.order_on.length).fill(project.order_on[0]) : project.order_on) : project.order_on[0],
                        written_by: project.quantity > 1 ? JSON.stringify(isAllTopicsOneDay ? Array(project.order_on.length).fill(project.written_by[0]) : project.written_by) : project.written_by[0],
                        files: files.length ? files.join(',') : '',
                    };
                } else {
                    obj = {
                        ...project,
                        type: 'marketplace',
                        job_type: isRequestTI ? 'ideas' : includes(action, ['update_add_to_order', 'save_add_to_order']) ? 'blogging' : 'writing',
                        order_on: project.order_on[0],
                        written_by: isRequestTI ? moment(project.order_on[0]).add(min_written_by, 'hours').format('YYYY-MM-DD HH:mm:ss') : project.written_by[0],
                        format: 'blog',
                        quantity: 1,
                        invite_writers,
                        topic: project.topic[0],
                        project_tags,
                        idea_project_id: project.idea_project_id,
                        idea_number: project.idea_number,
                        talent: 'bloggers',
                        pay_type: job_pay_type,
                        files: files.length ? files.join(',') : '',
                    };
                }
                actionObj = {
                    ...actionObj,
                    job_type: job_pay_type === 'one-off' ? 'writing' : isRequestTI ? 'ideas' : includes(action, ['update_add_to_order', 'save_add_to_order']) ? 'blogging' : 'writing',
                }
                Object.keys(obj).forEach(key => {
                    if (!includes(key, job_pay_type === 'one-off' ? fields.writing : isRequestTI ? fields.ideas : fields.writing)) {
                        delete obj[key];
                    }
                });
            } else if (job_type === 'design') {
                const image_option_files = project.image_option_files.map(({ file_id }) => file_id);
                const { def_written_by } = getTurnaround();

                obj = {
                    ...project,
                    type: 'marketplace',
                    invite_writers,
                    level: 'pro',
                    topic: project.topic[0],
                    files: files.length ? files.join(',') : '',
                    image_option_files,
                    order_on: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                    written_by: moment().utc().add(def_written_by, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                    pay_type: job_pay_type,
                    format_options: job_pay_type === 'one-off' ? project.format_options.map(o => JSON.stringify(o)) : null,
                };
                Object.keys(obj).forEach(key => {
                    if (!includes(key, fields.design)) {
                        delete obj[key];
                    }
                });
            } else if (job_type === 'motion') {
                const logo_file = project.logo_file.map(o => o.file_id);

                obj = {
                    ...project,
                    type: 'marketplace',
                    invite_writers,
                    topic: project.topic[0],
                    logo_file: logo_file.length ? logo_file.join(',') : '',
                    idea_files: idea_files.length ? idea_files.map(o => o.file_id) : '',
                    order_on: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                    pay_type: job_pay_type,
                    format_options: job_pay_type === 'one-off' ? project.format_options.map(o => JSON.stringify(o)) : null,
                };
                Object.keys(obj).forEach(key => {
                    if (!includes(key, fields.motion)) {
                        delete obj[key];
                    }
                });
            } else if (job_type === 'webaudit') {
                obj = {
                    ...project,
                    type: 'marketplace',
                    order_on: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                    pay_type: 'one-off',
                };
                Object.keys(obj).forEach(key => {
                    if (!includes(key, fields.webaudit)) {
                        delete obj[key];
                    }
                });
            } else if (job_type === 'seoaudit') {
                obj = {
                    ...project,
                    type: 'marketplace',
                    seoaudit_type,
                    order_on: moment().format('YYYY-MM-DD HH:mm:ss'),
                    pay_type: 'one-off',
                };
                Object.keys(obj).forEach(key => {
                    if (!includes(key, fields.seoaudit)) {
                        delete obj[key];
                    }
                });
            } else if (job_type === 'keywords') {
                obj = {
                    ...project,
                    type: 'marketplace',
                    topic: project.topic[0],
                    files: files.length ? files.join(',') : '',
                    order_on: project.order_on[0],
                    written_by: project.written_by[0],
                };
                Object.keys(obj).forEach(key => {
                    if (!includes(key, fields.keywords)) {
                        delete obj[key];
                    }
                });
            } else if (job_type === 'ideas') {
                obj = {
                    ...project,
                    type: 'marketplace',
                    quantity: 1,
                    invite_writers,
                    is_subscription: !isNil(words_balance) && !isEmpty(words_balance) && !!words_balance ? 1 : 0,
                    topic: project.format === 'product' ? project.product_list[0] : project.topic[0],
                    files: files.length ? files.join(',') : '',
                    order_on: project.order_on[0],
                    written_by: project.written_by[0],
                };
                Object.keys(obj).forEach(key => {
                    if (!includes(key, fields.ideas)) {
                        delete obj[key];
                    }
                });
            } else {
                const qty = project.quantity;
                const topic =
                    project.format === 'product'
                        ? includes(job_type, 'proofreading')
                            ? project.product_list[0]
                            : qty === 1
                                ? project.product_list[0]
                                : `${qty} product description${qty > 1 ? 's' : ''}`
                        : qty > 1
                            ? JSON.stringify(project.topic)
                            : project.topic[0];
                const word_count =
                    qty > 1
                        ? project.format === 'product'
                            ? project.word_count[0]
                            : JSON.stringify(project.word_count)
                        : Array.isArray(project.word_count)
                            ? project.word_count[0]
                            : project.word_count;
                const quantity = project.format === 'product' ? 1 : qty;

                obj = {
                    ...project,
                    type: 'marketplace',
                    word_count,
                    topic,
                    invite_writers,
                    project_tags,
                    talent: job_type === 'writing' && job_pay_type === 'subscription' ? 'writers' : null,
                    pay_type: job_pay_type,
                    files: files.length ? files.join(',') : '',
                    order_on: project.quantity > 1 && project.format !== 'product' ? JSON.stringify(isAllTopicsOneDay ? Array(project.order_on.length).fill(project.order_on[0]) : project.order_on) : project.order_on[0],
                    written_by: project.quantity > 1 && project.format !== 'product' ? JSON.stringify(isAllTopicsOneDay ? Array(project.order_on.length).fill(project.written_by[0]) : project.written_by) : project.written_by[0],
                    quantity: includes(action, ['save_add_to_order', 'save_draft']) ? quantity : project.quantity
                };
                Object.keys(obj).forEach(key => {
                    if (!includes(key, fields.writing)) {
                        delete obj[key];
                    }
                });
            }

            if (action === 'save_draft') {
                obj.is_draft = 1;
            }

            if (isAuth && isEmpty(proposal)) {
                if ((includes(job_type, 'writing/design/motion/blogging') && job_pay_type === 'subscription') && includes(action, ['update_add_to_order', 'save_add_to_order'])) {
                    confirmProjectAsync(obj, actionObj, navigate);
                } else if (includes(action, ['update_add_to_order', 'update_draft_order', 'update_draft'])) {
                    updateProjectAsync(obj, actionObj, null, navigate);
                } else if (includes(action, ['save_add_to_order', 'save_draft'])) {
                    createProjectAsync(obj, actionObj, navigate);
                }
            } else {
                setModal('createAccount');
                updateOrderState({ formProject: obj, formAction: actionObj, });
            }
        } else {
            animateScrollTo(document.getElementById(formFields[0]), {
                verticalOffset: -100,
            });
        }
    };

    useEffect(() => {
        // Устанавливаем значение checkbox-а Order all topics one day в true. Сам checkbox прячем
        setIsAllTopicsOneDay(true);

        if (!isEmpty(search)) {
            const str = search.substring(1).split('&')[0];
            const { query } = new Url(window.location.href, true);

            if (includes(str, jobTypesArr)) {
                updateOrderState(getOrderStateConfig(str, query.type));
            }
            const formObj = parseUrl();
            if (!isEmpty(formObj)) {
                updateFormState(getFormLabel(getOrderStateConfig(str).job_type), formObj);
            }
        }
        // TODO orderAction
        // if ( isAuth && !isNil(orderAction) ) {
        //     onSubmit({currentTarget: { dataset: { action: orderAction }}});
        //     if( !isEmpty(isCheckedEmailProposal) ) {
        //         updateProposalCompanyAsync(isCheckedEmailProposal.company, false);
        //     }
        // }
    }, []);
    useEffect(() => {
        if (!isNil(job_type)) {
            if (order_on === null) {
                updateFormState(getFormLabel(), getDefaultsDates());
            }
            if (page === 'editing') {
                const { min_written_by, def_written_by } = getTurnaround();
                let data = {};
                const isOrderOnExpired = moment(order_on[0]).diff(moment(), 'hours') < 0;
                const isWrittenByExpired = moment(written_by[0]).diff(moment().add(min_written_by, 'hours'), 'hours') < 0;

                if (isOrderOnExpired || !order_on[0]) {
                    data = {
                        order_on: [moment().utc().format('YYYY-MM-DD HH:mm:ss')],
                    };
                }
                if (isWrittenByExpired || !written_by[0]) {
                    data = {
                        ...data,
                        written_by: [moment().utc().add(def_written_by, 'hours').format('YYYY-MM-DD HH:mm:ss')],
                    };
                }

                if (!isEmpty(data)) {
                    updateFormState(getFormLabel(), data);
                }
            }
        }
    }, [job_type, job_pay_type, topic_ideas_type, page]);
    useEffect(() => {
        if (isAuth && !isNil(job_type) && page !== 'editing') {
            if (job_type === 'seoaudit') return;
            updateFormState(getFormLabel(), { localization: getLocalization(currency) });
        }
    }, [job_type, job_pay_type, topic_ideas_type, currency]);
    useEffect(() => {
        if (!isAuth && !isNil(job_type) && !isEmpty(orderCurrency)) {
            if (job_type === 'seoaudit') return;
            updateFormState(getFormLabel(), { localization: getLocalization(orderCurrency) });
        }
    }, [orderCurrency, job_type]);
    useEffect(() => {
        if (!isNil(inviteTalentsObj)) {
            updateFormState(getFormLabel(), { ...inviteTalentsObj });
        }
    }, [inviteTalentsObj]);
    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e, noWritersHintRef.current, () => { setUiState('isEmptyWriters', false) });
        };

        if (isEmptyWriters) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isEmptyWriters]);
    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e, turnaroundHintRef.current, () => { setIsTurnaroundHint(false); });
        };

        if (isTurnaroundHint) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isTurnaroundHint]);
    useEffect(() => {
        const onOutsideClick = (e) => {
            const el = document.querySelectorAll(`.gac-daypicker-container[data-i="${dayPicker}"]`)[0];
            onOutsideElClick(e, el, () => { setDayPicker('') });
        };

        if (!isEmpty(dayPicker)) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [dayPicker]);
    useLayoutEffect(() => {
        if (turnaround_type === 'custom') {
            if (!isNil(turnaroundCustomRef.current)) {
                turnaroundCustomRef.current.focus();
            }
        }
    }, [turnaround_type]);
    useLayoutEffect(() => {
        if (brief_links_shared === 'no') {
            addField('brief');
        }
    }, [brief_links_shared]);
    useEffect(() => {
        const { query } = new Url(window.location.href, true);

        if (!isNil(query.currency) && currencyList.some(o => o.value === query.currency)) {
            setOrderState('orderCurrency', query.currency);
        } else {
            if (isEmpty(ipDetails)) {
                setOrderState('orderCurrency', 'usd');
            } else {
                const country_code = ipDetails.country_code2.toLowerCase();
                setOrderState('orderCurrency', country_code === 'au' ? 'aud' : country_code === 'ca' ? 'cad' : country_code === 'gb' ? 'gbp' : 'usd');
            }
        }

    }, [ipDetails]);
    useEffect(() => {
        if (!isNil(isEmailExist) && isNil(activeModal)) {
            const { query } = new Url(window.location.href, true);
            const email = decodeURIComponent(query.email);
            const phone = decodeURIComponent(query.phone);
            const fullName = decodeURIComponent(query.full_name);
            setUiState('loginEmail', email);
            
            if(phone){
                setUiState('createAccountPhone', phone);
            }
            if(fullName){
                setUiState('createAccountFullName', fullName);
            }
            if (isEmailExist) {
                setModal('createAccount');
            } else {
                sendProspectAsync(email, phone);
            }
        }
    }, [isEmailExist]);
    // TODO orderAction
    // useEffect(() => {
    //     if ( isAuth && isProposalCompanyUpdated && !isNil(orderAction) ) {
    //         onSubmit({currentTarget: { dataset: { action: orderAction }}});
    //     }
    // }, [isProposalCompanyUpdated]);

    /* Html */
    const inputProps = { 'data-hj-whitelist': true, 'type': 'text' };
    const getProjectTitle = () => {
        const title = includes(job_type, 'design/motion') ? getDesignFormat(formats[job_type], format) : getFormat(formats['writing'], format);
        // const topic = quantity > 1 ? form.topic.join('\n') : form.topic[0];

        return <div className='gac-new-project-title'>
            {job_type === 'keywords'
                ? <>{'Keyword research '}</>
                : <>{`${quantity > 1 ? `${quantity}x ` : ''} ${title ? title : 'New project '} ${word_count ? `${toLocaleString(word_count.toString())}w ` : ''}`}</>}
            {isMobile && <br />}
            {/*{ topic && quantity === 1 && <span>{ !isMobile ? 'I ' : '' }{ form.topic }</span> }*/}
        </div>;
    };
    const getJobType = () => {
        const getJobTypeIcon = () => {
            if (isNil(job_group) || isNil(job_type) || (includes(job_type, 'writing/design/motion/blogging') && isNil(job_pay_type))) {
                return <div style={{ paddingTop: !isNil(job_type) ? 104 : !isNil(job_group) ? 176 : 240 }} className="gac-form-radio-icon"><JobTypeIcon /></div>;
            }
            return null;
        };
        const getJobTypeForRole6 = () => {
            const isEmptyWordsBalance = words_balance < 1 || !words_balance;
            const config = [
                { value: 'design', label: 'Design', isDisabled: !isActiveDesignSub(accountId, subscriptions), },
                { value: 'blogging', label: 'Blogging', isDisabled: type !== 'bloggers' || isEmptyWordsBalance },
                { value: 'writing', label: 'Writing', isDisabled: type !== 'writers' || isEmptyWordsBalance },
            ];
            const html = config.map(({ value, label, isDisabled }) => {
                return <div key={value} data-value={value} onClick={isDisabled ? () => { } : onJobTypeChange} className={`gac-radio-item ${job_type === value ? 'gac-item-active' : ''} ${isDisabled ? 'gac-item-disabled' : ''}`}>{label}</div>;
            });

            return <div className='gac-form-row'>
                <div className='gac-form-field'>
                    <span className='gac-form-label'>Job type</span>
                    <div className={`gac-form-radio gac-radio-job-type-role6`}>
                        {html}
                    </div>
                    {isNil(job_type) && <div style={{ paddingTop: 240 }} className="gac-form-radio-icon"><JobTypeIcon /></div>}
                </div>
            </div>;
        };

        if (isAuth && isSelfClientFn(user_role)) {
            return getJobTypeForRole6();
        }

        /* Job types */
        const getDesignJobTypes = () => {
            const config = [
                { value: 'design', label: 'Graphic design' },
                { value: 'motion', label: 'Motion graphics' },
            ];
            const html = config.map(({ value, label }) => (
                <div key={value} data-value={value} onClick={onJobTypeChange} className={`gac-radio-item ${job_type === value ? 'gac-item-active' : ''}`}>{label}</div>
            ));

            return (
                <div className={`gac-form-radio gac-radio-job-group-design ${page === 'editing' || isStrict(search) ? 'gac-disabled' : ''}`}>
                    {html}
                </div>
            );
        };

        const getWritingJobTypes = () => {
            const config = [
                { value: 'writing', label: 'Content writing' },
                { value: 'blogging', label: 'Blogging' },
                { value: 'aih', label: 'AI+Human' },
                { value: 'ideas', label: 'Topic ideas' },
                { value: 'proofreading', label: 'Proofreading' },
            ];
            const html = config.map(({ value, label }) => {
                return <div key={value} data-value={value} onClick={onJobTypeChange} className={`gac-radio-item ${job_type === value ? 'gac-item-active' : ''}`}>{label}</div>;
            });

            return <div className={`gac-form-radio gac-radio-job-group-writing ${page === 'editing' || isStrict(search) ? 'gac-disabled' : ''}`}>
                {html}
            </div>;
        };
        const getSeoJobTypes = () => {
            const config = [
                { value: 'webaudit', label: 'Website audit' },
                { value: 'seoaudit', label: 'SEO audit' },
                { value: 'keywords', label: 'Keyword research' },
            ];
            const html = config.map(({ value, label }) => {
                return <div key={value} data-value={value} onClick={onJobTypeChange} className={`gac-radio-item ${job_type === value ? 'gac-item-active' : ''}`}>{label}</div>;
            });

            return <div className={`gac-form-radio gac-radio-job-group-seo ${page === 'editing' || isStrict(search) ? 'gac-disabled' : ''}`}>
                {html}
            </div>;
        };
        const getJobGroup = () => {
            const config = [
                { value: 'design', label: 'Design' },
                { value: 'writing', label: 'Writing' },
                { value: 'seo', label: 'SEO' },
            ];
            const html = config.map(({ value, label }) => {
                return <div key={value} data-value={value} onClick={onJobGroupChange} className={`gac-radio-item ${job_group === value ? 'gac-item-active' : ''}`}>{label}</div>;
            });

            return <>
                <div className={`gac-form-radio gac-radio-job-group ${page === 'editing' || isStrict(search) ? 'gac-disabled' : ''}`}>
                    {html}
                </div>
            </>
        };
        const getJobGroupContent = () => {
            const config = {
                'design': getDesignJobTypes(),
                'writing': getWritingJobTypes(),
                'seo': getSeoJobTypes(),
            };

            return config[job_group];
        };
        const getJobPayType = () => {
            if (!includes(job_type, 'writing/design/motion/blogging')) return null;

            let config = [
                { value: 'one-off', label: 'One-off project' },
                { value: 'subscription', label: 'Subscription' },
            ];

            const sub = getActiveDesignSub(accountId, subscriptions);
            const planName = isNil(sub) ? '' : sub.plan_name;

            switch (true) {
                case job_type === 'writing' && type === 'writers' && words_balance > 0 && !!words_balance:
                    config = reverse(config);
                    break;
                case (job_type === 'design' && isActiveDesignSub(accountId, subscriptions)) || isActiveWebBuildingSub(accountId, subscriptions):
                    config = reverse(config);
                    break;
                case (job_type === 'motion' && isActiveDesignSub(accountId, subscriptions) && planName !== 'Pro' && planName !== 'Double') || isActiveWebBuildingSub(accountId, subscriptions):
                    config = reverse(config);
                    break;
                case job_type === 'blogging' && type === 'bloggers' && words_balance > 0 && !!words_balance:
                    config = reverse(config);
                    break;
            }

            const html = config.map(({ value, label }) => {
                return <div key={value} data-value={value} onClick={onJobPayTypeChange} className={`gac-radio-item ${job_pay_type === value ? 'gac-item-active' : ''}`}>{label}</div>;
            });

            return <div className='gac-form-row'>
                <div className='gac-form-field'>
                    <div className={`gac-form-radio gac-radio-job-pay-type ${page === 'editing' || isStrict(search) ? 'gac-disabled' : ''}`}>
                        {html}
                    </div>
                </div>
            </div>;
        };

        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Job type</span>
                {getJobGroup()}
                {getJobGroupContent()}
                {getJobPayType()}
                {getJobTypeIcon()}
            </div>
        </div>;
    };

    const getFormatField = () => {
        const { writing } = formats;
        let items = writing.filter(o => o.value !== 'sales');
        if (job_type === 'aih') items = items.filter(o => !includes(o.value, 'page/paper'));
        const title = getFormat(writing, format);
        return <div className='gac-form-row'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Format</span>
                <Select items={items} value={format ? format : ''} title={title} parameter='format' invalid={invalidFields.includes('format')} onChange={onFormFieldChange} />
            </div>
        </div>;
    };
    const getIndustryField = () => {
        const title = getTitle(industries, industry);
        const isDirectionTop = job_type === 'blogging' && job_pay_type === 'subscription' && topic_ideas_type === 'existing';

        return <div className='gac-form-row gac-row-multiple'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Industry</span>
                <Select direction={isDirectionTop ? 'top' : null} isMultiple={false} items={industries} value={industry ? industry : ''} title={title} parameter='industry' invalid={invalidFields.includes('industry')} onChange={onFormFieldChange} />
            </div>
            {industry === 'other' && <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Industry name</span>
                <div className='gac-form-input'>
                    <input {...inputProps} className={invalidFields.includes('other_industry') ? 'gac-invalid' : ''} id='other_industry' value={other_industry} onChange={onInputFieldChange} />
                </div>
            </div>}
        </div>;
    };
    const getSuggestedWordCount = () => {
        const arr = job_type === 'aih' ? suggested.word_count_aih : suggested.word_count;
        const data = (arr.filter(o => o.format === (format ? format : 'custom'))[0] || {}).word_count;

        return data ? data.split(',') : [];
    };
    const getWordCountsItemsField = () => {
        const isReadOnly = job_type === 'proofreading' && !isEmpty(clearBrief(content));
        return getSuggestedWordCount().map((item, i) => {
            return <div key={item} data-value={item} data-prop='word_count_type' className={`gac-word-count-item ${word_count_type === item ? 'gac-item-active' : ''} ${isReadOnly ? 'gac-item-disabled' : ''}`} onClick={isReadOnly ? () => { } : onWordCountChange}>
                <i className={`gac-icon-${item}`} />
                <span>{toLocaleString(item.toString())}</span>
            </div>;
        });
    };
    const getWordCountsCustomField = () => {
        const isReadOnly = job_type === 'proofreading' && !isEmpty(clearBrief(content));
        return <div onClick={isReadOnly ? () => { } : onWordCountChange} data-value='custom' data-prop='word_count_type' className={`gac-word-count-item gac-item-custom ${word_count_type === 'custom' ? 'gac-item-active' : ''}`}>
            <i className='gac-icon-custom' />
            <input id='word_count' style={{ borderColor: isWordCountValid ? '#e9eff3' : '#dc5d5b', }}
                {...inputProps}
                ref={wordCountRef} // this.word_count
                value={word_count_type === 'custom' && word_count ? toLocaleString(word_count) : ''}
                onBlur={onWordCountInputBlur}
                onFocus={onWordCountInputFocus}
                onChange={onInputFieldChange}
                readOnly={isReadOnly}
                placeholder='Custom' />
            {isReadOnly && <div className="gac-read-only" />}
        </div>;
    };
    const getProofreadingWordCountField = () => {
        const wordCounts = getWordCountsItemsField();

        return <div className='gac-form-row gac-row-multiple gac-row-quantity'>
            {wordCounts.length
                ? <div className='gac-form-field gac-field-word-count'>
                    <span className='gac-form-label'>Word count</span>
                    <div className='gac-word-count-wrap'>
                        <div id='word_count_type' className={`gac-word-count-wrapper ${!isWordCountValid ? '' : invalidFields.includes('word_count_type') ? 'gac-invalid' : ''}`}>
                            {getWordCountsCustomField()}
                            {wordCounts}
                        </div>
                    </div>
                </div>
                : null}
        </div>;
    };
    const getIdeaCountsItemsField = () => {
        // const activeSub = getActiveWritingSub(accountId, user.companySubscriptions);
        const isActiveSub = false;
        return isEmpty(suggested.idea_count) ? [] : suggested.idea_count.map(({ count }, i) => {
            return <div onClick={isActiveSub ? () => { } : onWordCountChange} key={count} data-value={`${count}`} data-prop='idea_count_type' className={`gac-word-count-item ${idea_count_type === `${count}` ? 'gac-item-active' : ''} ${isActiveSub ? 'gac-item-disabled' : ''}`}>
                <i className={`gac-icon-${i === 0 ? '400' : i === 1 ? '600' : '1000'}`} />
                <span>{count}</span>
            </div>;
        });
    };
    const getWordCountSimpleField = () => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Word count</span>
                <div className='gac-simple-word-count'>
                    <WordCount />
                    <input {...inputProps} id='word_count' className={invalidFields.includes('word_count') ? 'gac-invalid' : ''} ref={wordCountRef} value={toLocaleString(word_count)} onBlur={onWordCountInputBlur} onChange={onInputFieldChange} />
                    of <span style={{ color: invalidFields.includes('word_count') ? '#EE5050' : null, marginLeft: 5 }}>{toLocaleString(words_balance)} words</span>
                </div>
            </div>
        </div>;
    };
    const getCustomField = () => {
        // const activeSub = getActiveWritingSub(accountId, user.companySubscriptions);
        const isReadOnly = false; // job_type === 'ideas' && !isEmpty(activeSub);
        let prop = 'word_count';
        let type = word_count_type;
        let ref = wordCountRef;
        let value = word_count;
        if (job_type === 'ideas') {
            prop = 'idea_count';
            type = idea_count_type;
            ref = ideaCountRef;
            value = idea_count;
        }
        return <div data-value='custom' data-prop={`${prop}_type`} className={`gac-word-count-item gac-item-custom ${type === 'custom' ? 'gac-item-active' : ''}`} onClick={isReadOnly ? () => { } : onWordCountChange}>
            <i className='gac-icon-custom' />
            <input id={prop} style={{ borderColor: isWordCountValid ? '#e9eff3' : '#dc5d5b' }}
                {...inputProps}
                ref={ref}
                value={type === 'custom' && value ? toLocaleString(value) : ''}
                onBlur={onWordCountInputBlur}
                onFocus={onWordCountInputFocus}
                onChange={onInputFieldChange}
                placeholder='Custom' />
            {isReadOnly && <div className="gac-read-only" />}
        </div>;
    };
    const getQuantityField = () => {
        const wordCounts = getWordCountsItemsField();
        const ideaCounts = getIdeaCountsItemsField();

        return <div className='gac-form-row gac-row-multiple gac-row-quantity'>
            {(page !== 'editing' || (page === 'editing' && format === 'product')) && job_type !== 'ideas' &&
                <div className='gac-form-field gac-field-quantity'>
                    <span className='gac-form-label'>Quantity</span>
                    <span onClick={decreaseQty} className={`gac-decrease-qty ${quantity === 1 ? 'gac-qty-inactive' : ''}`}>
                        <Minus />
                    </span>
                    <input id='quantity' type='number' value={quantity} data-hj-whitelist onChange={onQtyChange} />
                    <span onClick={increaseQty} className={`gac-increase-qty ${quantity === 999 ? 'gac-qty-inactive' : ''}`}>
                        <Plus />
                    </span>
                </div>}
            {job_type === 'ideas'
                ? <div className='gac-form-field gac-field-word-count'>
                    <span className='gac-form-label'>Idea count</span>
                    <div className='gac-word-count-wrap'>
                        <div id='idea_count_type' className={`gac-word-count-wrapper ${!isWordCountValid ? '' : invalidFields.includes('idea_count_type') ? 'gac-invalid' : ''}`}>{ideaCounts}{getCustomField()}</div>
                    </div>
                </div>
                : wordCounts.length
                    ? <div className='gac-form-field gac-field-word-count'>
                        <span className='gac-form-label'>Word count</span>
                        <div className='gac-word-count-wrap'>
                            <div id='word_count_type' className={`gac-word-count-wrapper ${!isWordCountValid ? '' : invalidFields.includes('word_count_type') ? 'gac-invalid' : ''}`}>{wordCounts}{getCustomField()}</div>
                        </div>
                    </div>
                    : null}
        </div>;
    };
    const getCurrencyField = () => {
        if (isAuth) return null;

        const titleCurrency = orderCurrency ? currencyList.filter(o => o.value === orderCurrency)[0].title : '';

        return <div className='gac-form-field gac-field-1-2 gac-currency-select'>
            <span className='gac-form-label'>Currency</span>
            <Select isMultiple={false} items={currencyList} value={orderCurrency} title={titleCurrency} parameter='currency' onChange={onOrderCurrencyChange} />
        </div>;
    };
    const getLevelField = () => {
        const currencySign = getCurrencySign(orderCurrency);
        const wordCountValue = word_count_type && word_count_type !== 'custom' ? Number(word_count_type) : Array.isArray(word_count) ? word_count[0] : word_count;
        const ideaCountValue = idea_count_type && idea_count_type !== 'custom' ? Number(idea_count_type) : idea_count;
        const isIdeas = job_type === 'ideas';
        // const isProof = job_type === 'proofreading';

        let rates = [];
        if (isIdeas) {
            rates = ratesData[job_type]
                .filter((item) => item.currency === (isAuth ? currency : currencySign));
        } else {
            rates = ratesData[job_type === 'aih' ? 'proofreading' : job_type === 'blogging' ? 'writing' : job_type]
                .filter((item) => item.currency === (isAuth ? currency : currencySign))
                .filter((item) => item.format.includes((format ? format : 'custom')));
        }

        const pro_rate = isEmpty(rates) ? '' : rates.filter((o) => o.level === 'pro')[0].rate_client;
        const expert_rate = isEmpty(rates) ? '' : rates.filter((o) => o.level === 'expert')[0].rate_client;
        const proRate = Math.round(pro_rate * (isIdeas ? ideaCountValue : wordCountValue));
        const expertRate = Math.round(expert_rate * (isIdeas ? ideaCountValue : wordCountValue));
        const isActiveSub = false; //!isEmpty(activeSub)

        return <div className={`gac-form-row gac-form-level ${!isAuth ? 'gac-row-multiple gac-row-currency' : ''}`}>
            <div className='gac-form-field gac-writer-level'>
                <span className='gac-form-label'>{includes(job_type, 'writing,ideas,aih,blogging') ? 'Writer ' : 'Proofreader '}level</span>
                <div className='gac-form-field-content'>
                    <div id='level' className={`gac-form-radio ${isActiveSub && isIdeas ? 'gac-disabled' : ''} ${invalidFields.includes('level') ? 'gac-invalid' : ''}`}>
                        <div onClick={onLevelChange} data-value='pro' data-prop='level' className={`gac-radio-item ${level === 'pro' ? 'gac-item-active' : ''}`}>
                            <i className='gac-star-icon' />
                            <span>Pro </span>
                            <span style={{ textDecoration: isActiveSub && isIdeas ? 'line-through' : 'none' }}>{`${proRate ? `${(isAuth ? currency : currencySign)}${toLocaleString(`${proRate}`)} ` : ''} ${quantity > 1 ? ` x${quantity}` : ''} `}</span>
                            {isActiveSub && isIdeas && <span style={{ marginLeft: 4, color: '#43b993' }}>{currency}0</span>}
                        </div>
                        <div onClick={onLevelChange} data-value='expert' data-prop='level' className={`gac-radio-item ${level === 'expert' ? 'gac-item-active' : ''}`}>
                            <i className='gac-expert-icon' />
                            <span>Expert </span>
                            <span>{`${expertRate ? `${(isAuth ? currency : currencySign)}${toLocaleString(`${expertRate}`)}` : ''} ${quantity > 1 ? ` x${quantity}` : ''}`}</span>
                        </div>
                    </div>
                    <Hint
                        text={[]}
                        type='success'
                        title='What’s the difference'>
                        {includes(job_type, 'writing,ideas,aih')
                            ? <>
                                <div>All of our writers – regardless of level – are carefully vetted native English speakers with experience writing SEO-optimized copy.</div>
                                <div><strong>Pro</strong>: Hand-chosen by our demanding in-house editors. Only 2% of all applicants make it as a Pro copywriter. These <strong>top-quality copywriters</strong> have proven expertise in specific industries and create content that produces measurable results for our clients.</div>
                                <div><strong>Expert</strong>: With deep industry-specific knowledge, these <strong>elite copywriters</strong> produce content that positions our clients as <strong>thought leaders</strong> in their respective industries. Our Expert copywriter’s client work appears on some of the world’s top online resources.</div>
                            </>
                            : <>
                                <div>All of our proofreaders – regardless of level – are carefully vetted native English speakers with experience writing SEO-optimized copy.</div>
                                <div><strong>Pro</strong>: Hand-chosen by our demanding in-house editors. Only 2% of all applicants make it as a Pro proofreaders. These <strong>top-quality proofreaders</strong> have proven expertise in specific industries and create content that produces measurable results for our clients.</div>
                                <div><strong>Expert</strong>: With deep industry-specific knowledge, these <strong>elite proofreaders</strong> produce content that positions our clients as <strong>thought leaders</strong> in their respective industries. Our Expert proofreader's client work appears on some of the world’s top online resources.</div>
                            </>}
                    </Hint>
                </div>
            </div>
            {getCurrencyField()}
        </div>;
    };
    const getTopicWritingField = () => {
        const topicValue = quantity > 1 ? topic.join('\n') : topic[0];
        const productListValue = quantity > 1 ? product_list.join('\n') : product_list[0];
        const textareaHeight = quantity * 22 + 26 + 'px';
        let rows = [];
        for (let i = 1; i <= quantity; i++) {
            rows.push(<span key={i}>{i}.</span>);
        }
        const wordCountInputs = [];
        const isProductFormat = format === 'product';
        if (quantity > 1) {
            for (let i = 0; i < quantity; i++) {
                wordCountInputs.push(
                    <input
                        {...inputProps}
                        id={`input-${i}`}
                        data-i={i}
                        key={i}
                        disabled={isProductFormat}
                        value={word_count[i] === 0 ? '' : toLocaleString(word_count[i].toString())}
                        onKeyDown={onWordCountKeyDown}
                        onKeyUp={onWordCountKeyUp}
                        onChange={onWordCountArrayChange} />
                );
            }
        }

        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>{isProductFormat ? 'Product titles' : 'Topic'}</span>
                {quantity > 1
                    ? <><div className='gac-field-topic-textarea'>
                        <div className='gac-field-topic-rows'>{rows}</div>
                        <textarea
                            style={{ height: textareaHeight, minHeight: textareaHeight, maxHeight: textareaHeight }}
                            data-hj-whitelist
                            ref={topicArrRef}
                            className={invalidFields.includes(isProductFormat ? 'product_list_array' : 'topic_array') ? 'gac-invalid' : ''}
                            id={isProductFormat ? 'product_list_array' : 'topic_array'}
                            rows={quantity}
                            value={isProductFormat ? productListValue : topicValue}
                            onBlur={onTopicFocusOut}
                            onChange={onInputFieldChange} />
                        <span className='gac-topic-textarea-border' />
                        <div className='gac-wordcount-textarea'>
                            <span className='gac-form-label'>Words</span>
                            <div id='word_count_array' style={{ height: textareaHeight }} className={`gac-wordcount-textarea-wrap ${invalidFields.includes('word_count_array') ? 'gac-invalid' : ''}`}>{wordCountInputs}</div>
                        </div>
                        {!isTopicUnique && <div className='gac-warning'>You’ve ordered one of the topics before</div>}
                        {isSameTopics && <div className='gac-warning'>You’ve entered the same topic more than once</div>}
                    </div>
                        {invalidFields.includes(isProductFormat ? 'product_list_array' : 'topic_array')
                            && (isProductFormat
                                ? product_list.some(o => o.length > 150)
                                : topic.some(o => o.length > 150))
                            && <div className='gac-error'>Please limit topic to 150 characters</div>}
                    </>
                    : <div className='gac-form-input'>
                        <input
                            {...inputProps}
                            className={invalidFields.includes(isProductFormat ? 'product_list' : 'topic') ? 'gac-invalid' : ''}
                            id={isProductFormat ? 'product_list' : 'topic'}
                            value={isProductFormat ? productListValue : topicValue}
                            onBlur={onTopicFocusOut}
                            onChange={onInputFieldChange} />
                        {!isTopicUnique && <div className='gac-warning'>You’ve ordered this before</div>}
                        {invalidFields.includes(isProductFormat ? 'product_list' : 'topic')
                            && (isProductFormat ? productListValue.length > 150 : topicValue.length > 150)
                            && <div className='gac-error'>Please limit topic to 150 characters</div>}
                    </div>}
            </div>
        </div>;
    };
    const getBriefField = (isDropzone) => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>{job_type === 'keywords' ? 'Primary services or products' : 'Brief'}</span>
                <div id='brief' className={`gac-form-textarea ${invalidFields.includes('brief') || isBriefLimit ? 'gac-invalid' : ''}`}>
                    <textarea id={`${job_type}-brief`} value={brief} readOnly />
                </div>
            </div>
            {isDropzone && getDropzoneHtml()}
            {brief_links_shared === 'no' && <div className='gac-error'>The link sharing should be set to 'Anyone with the link' or 'Public on the web'</div>}
            {isBriefLimit && <div className='gac-error'>Please limit brief to 30,000 characters</div>}
        </div>;
    };
    const getWritersField = () => {
        const writers = invite_writers ? invite_writers : [];
        const label = includes(job_type, 'writing,ideas,blogging,aih') ? ' writers' : includes(job_type, 'design/motion') ? ' designers' : ' proofreaders';
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Invite {label}</span>
                <div className='gac-invite-writers'>
                    <span onClick={inviteAllWriters} className={`gac-writers-all ${!writers.length ? 'gac-active' : ''}`}>All</span>
                    <span className='gac-writers-label'>or</span>
                    <div onClick={showModalWriters} className='gac-writers-list'>
                        {generateWriters()}
                        {(isEmptyWriters && !writers.length) && <div ref={noWritersHintRef} className='gac-no-writers'>No {label} on your team yet</div>}
                    </div>
                </div>
            </div>
        </div>;
    };
    const getTurnaroundField = () => {
        const type = turnaround_type;
        const custom = turnaround_custom;
        const { def_written_by, min_written_by } = getTurnaround();

        return <div className='gac-form-row gac-row-turnaround'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Turnaround, <strong>business days</strong></span>
                <div className='gac-form-field-content'>
                    <div className={`gac-form-radio `}>
                        {isTurnaroundHint && <div ref={turnaroundHintRef} className="gac-radio-item-hint">Available on Pro and Expert level</div>}
                        <div
                            onClick={onTurnaroundTypeChange}
                            data-value='expedited'
                            data-prop='turnaround_type'
                            data-written-by-hours={min_written_by}
                            className={`gac-radio-item ${type === 'expedited' ? 'gac-item-active' : ''}`}>
                            Expedited: {min_written_by / 24} days
                        </div>
                        <div
                            onClick={onTurnaroundTypeChange}
                            data-value='regular'
                            data-prop='turnaround_type'
                            data-written-by-hours={def_written_by}
                            className={`gac-radio-item ${type === 'regular' ? 'gac-item-active' : ''}`}>
                            Regular: {def_written_by / 24} days
                        </div>
                        <div
                            onClick={onTurnaroundTypeChange}
                            data-value='extended'
                            data-prop='turnaround_type'
                            style={{ width: 196, paddingRight: 20 }}
                            data-written-by-hours={def_written_by + 96}
                            className={`gac-radio-item ${type === 'extended' || type === 'custom' ? 'gac-item-active' : ''}`}>
                            Extended: {type === 'custom'
                                ? <input
                                    {...inputProps}
                                    ref={turnaroundCustomRef}
                                    id='turnaround_custom'
                                    value={custom}
                                    data-min={min_written_by / 24}
                                    onBlur={onTurnaroundCustomBlur}
                                    onChange={onInputFieldChange}
                                />
                                : (def_written_by + 96) / 24} days
                            <i onClick={onTurnaroundCustomClick} className={`gac-plus ${type === 'custom' ? 'gac-active' : ''}`} />
                        </div>
                    </div>
                    <Hint text={[]} type='success' title='How is it calculated'>
                        <div>Extended: Longer turnaround times attracts writers who are in demand.</div>
                        <div>Regular: Optimal choice for most projects.</div>
                        <div>Expedited: Subject to availability. <span onClick={() => { setModal('messageSupport') }}>Message support</span> to confirm delivery dates.</div>
                        <div>NB! Timer starts when a writer is matched to a project.</div>
                    </Hint>
                </div>
            </div>
        </div>;
    };
    const getOrderOnField = () => {
        if (isNil(order_on)) return null;
        const getOrderOnFieldHtml = (label, i, value) => {
            return <div key={i} className='gac-form-field gac-field-order_on'>
                <span className='gac-form-label'>{label}</span>
                <div className='gac-form-input gac-input-order-on'>
                    <div data-i={i} className="gac-daypicker-container">
                        <input
                            {...inputProps}
                            data-i={i}
                            className={`gac-input gac-input-calendar ${invalidFields.includes('order_on') && (isEmpty(value) || isNil(value)) ? 'gac-invalid' : ''}`}
                            readOnly
                            value={isEmpty(value) || isNil(value) ? 'Date .....' : moment(value).format('MMM D, YYYY')}
                            onClick={onOrderOnInputClick} />
                        {`${i}` === dayPicker && <DayPicker className='gac-daypicker-wrap' mode='single' selected={new Date(value)} fromDate={new Date()} onDayClick={onOrderOnChange} />}
                    </div>
                </div>
            </div>;
        };

        const orderOnFields = [];
        if (quantity > 1 && format !== 'product') {
            for (let i = 0; i < quantity; i++) {
                orderOnFields.push(getOrderOnFieldHtml(`${i + 1}${getOrdinal(i + 1)} topic on`, i, order_on[i]));
            }
        }

        return <div id='order_on' className='gac-form-row gac-row-multiple gac-row-order_on'>
            {quantity > 1 && format !== 'product' && !isAllTopicsOneDay ? orderOnFields : getOrderOnFieldHtml('Order on', 0, order_on[0])}
        </div>;
    };
    const getIsAllTopicsOneDayCheckbox = () => {
        // Прячем checkbox для возможности выбора разных дат для нескольких проектов
        return null;

        return <div className='gac-form-row gac-row-topics-one-day-checkbox'>
            <div className="gac-form-field">
                <label className='gac-form-checkbox' htmlFor="is-all-topics-one-day">
                    <input type='checkbox' id='is-all-topics-one-day' checked={isAllTopicsOneDay} onChange={onIsAllTopicsOneDayChange} />
                    <span />
                    Order all topics on one day
                </label>
            </div>
        </div>;
    };
    const getAdditionalDetailsLabel = () => {
        return (
            <div className='gac-form-row'>
                <div className='gac-form-title'>Additional details</div>
            </div>
        );
    };
    const getLocalizationSelectField = () => {
        const title = getTitle(localizations, localization);

        return <div className='gac-form-field gac-field-1-2'>
            <span className='gac-form-label'>Localization</span>
            <Select
                direction='top'
                isMultiple={false}
                items={localizations}
                value={localization ? localization : ''}
                title={title}
                parameter='localization'
                invalid={invalidFields.includes('localization')}
                onChange={onFormFieldChange} />
        </div>;
    };
    const getAddMoreDetailsBtn = () => {
        if (job_type === 'blogging' && topic_ideas_type === 'existing') return null;

        return <div onClick={toggleDetails} className={`gac-more-details ${isDetails ? 'gac-show-less' : ''} `}>
            <span><i /></span>
            {isDetails ? 'Show less' : 'Add more details'}
        </div>;
    };
    const getLocalizationField = () => {
        return (
            <div className='gac-form-row gac-row-multiple gac-row-details'>
                {getLocalizationSelectField()}
                {getAddMoreDetailsBtn()}
            </div>
        );
    };
    const getTagsField = () => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Tags</span>
                <div className='gac-custom-select'>
                    <Creatable
                        onInputChange={onTagsInputChange}
                        formatCreateLabel={formatCreateLabel}
                        placeholder=''
                        classNamePrefix='gac-select'
                        isMulti={true}
                        value={isNil(project_tags) ? [] : project_tags}
                        onChange={onTagsChange}
                        onCreateOption={onTagsCreate}
                        noOptionsMessage={noTagsMessage}
                        options={isNil(tags) ? [] : tags.map(o => ({ value: o.value, label: o.title }))} />
                </div>
            </div>
        </div>;
    };
    const getCopyStyleVoiceField = (direction) => {
        const titleCopystyle = getTitle(copy_styles, copy_style);
        const titleCopyvoice = getTitle(copy_voices, copy_voice);

        return <div className='gac-form-row gac-row-multiple'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Copy style</span>
                <Select
                    direction={direction}
                    isMultiple={false}
                    items={copy_styles}
                    value={copy_style ? copy_style : ''}
                    title={titleCopystyle}
                    parameter='copy_style'
                    onChange={onFormFieldChange} />
            </div>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Copy voice</span>
                <Select
                    direction={direction}
                    isMultiple={false}
                    items={copy_voices}
                    value={copy_voice ? copy_voice : ''}
                    title={titleCopyvoice}
                    parameter='copy_voice'
                    onChange={onFormFieldChange} />
            </div>
        </div>;
    };
    const getAdditionalDetailsFields = () => {
        const titleContent = getTitle(content_types, content);
        const titleTargetaudience = getTitle(target_audiences, target_audience);

        return <>
            <div className='gac-form-row gac-row-multiple'>
                <div className='gac-form-field gac-field-1-2'>
                    <span className='gac-form-label'>Target audience</span>
                    <Select isMultiple={false} items={target_audiences} value={target_audience ? target_audience : ''} title={titleTargetaudience} parameter='target_audience' onChange={onFormFieldChange} />
                </div>
                <div className='gac-form-field gac-field-1-2'>
                    <span className='gac-form-label'>Content</span>
                    <Select isMultiple={false} items={content_types} value={content ? content : ''} title={titleContent} parameter='content' onChange={onFormFieldChange} />
                </div>
            </div>
            <div className='gac-form-row'>
                <div className='gac-form-field'>
                    <span className='gac-form-label'>Mention</span>
                    <div className='gac-form-input'>
                        <input {...inputProps} id='mention' value={isNil(mention) ? '' : mention} onChange={onInputFieldChange} />
                    </div>
                </div>
            </div>
            <div className='gac-form-row'>
                <div className='gac-form-field'>
                    <span className='gac-form-label'>Avoid</span>
                    <div className='gac-form-input'>
                        <input {...inputProps} id='avoid' value={isNil(avoid) ? '' : avoid} onChange={onInputFieldChange} />
                    </div>
                </div>
            </div>
            <div className='gac-form-row'>
                <div className='gac-form-field'>
                    <span className='gac-form-label'>Similar content</span>
                    <div className='gac-form-input'>
                        <input {...inputProps} id='similar_content' value={isNil(similar_content) ? '' : similar_content} onChange={onInputFieldChange} />
                    </div>
                </div>
            </div>
        </>;
    };
    const getTopicField = () => {
        const isProductFormat = format === 'product';
        const topicValue = quantity > 1 ? topic.join('\n') : topic[0];
        const productListValue = quantity > 1 ? product_list.join('\n') : product_list[0];
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>{job_type === 'seoaudit' ? 'Link to the website' : includes(job_type, 'keywords,ideas,blogging') ? 'Website' : includes(job_type, 'design/motion') ? 'Project name' : 'Topic'}</span>
                <div className='gac-form-input'>
                    <input {...inputProps}
                        className={invalidFields.includes(isProductFormat ? 'product_list' : 'topic') ? 'gac-invalid' : ''}
                        id={isProductFormat ? 'product_list' : 'topic'}
                        value={isProductFormat ? productListValue : topicValue}
                        onBlur={onTopicFocusOut}
                        onChange={onInputFieldChange} />
                    {!isTopicUnique && <div className='gac-warning'>You’ve ordered this before</div>}
                    {invalidFields.includes(isProductFormat ? 'product_list' : 'topic')
                        && (isProductFormat ? productListValue.length > 150 : topicValue.length > 150)
                        && <div className='gac-error'>Please limit topic to 150 characters</div>}
                </div>
            </div>
        </div>;
    };
    const getTextareaField = (prop, label, isDropzone, getDropzoneFunction) => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>{label}</span>
                <div id={prop} className={`gac-form-textarea ${invalidFields.includes(prop) ? 'gac-invalid' : ''}`}>
                    <textarea id={`${prop}-textarea`} value={form[prop]} readOnly />
                </div>
            </div>
            {isDropzone && (isNil(getDropzoneFunction) ? getDropzoneHtml() : getDropzoneFunction())}
        </div>;
    };
    const getTargetAudienceField = () => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Target audience</span>
                <div className='gac-form-input'>
                    <input {...inputProps} id='target_audience' className={invalidFields.includes('target_audience') ? 'gac-invalid' : ''} value={target_audience} onChange={onInputFieldChange} />
                </div>
            </div>
        </div>;
    };
    const getContentGoalField = () => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Content goal</span>
                <div className='gac-form-input'>
                    <input {...inputProps} id='content_goal' className={invalidFields.includes('content_goal') ? 'gac-invalid' : ''} value={content_goal} onChange={onInputFieldChange} />
                </div>
            </div>
        </div>;
    };
    const getPlannedWordCountField = () => {
        return <div className='gac-form-row gac-row-multiple'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Planned word count</span>
                <div className='gac-form-input'>
                    <input {...inputProps} id='word_count' className={invalidFields.includes('word_count') ? 'gac-invalid' : ''} value={word_count ? word_count : ''} onChange={onInputFieldChange} />
                </div>
            </div>
            {getLocalizationSelectField()}
        </div>;
    };
    const getContentToProofreadField = () => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Content to proofread</span>
                <div id='content' className={`gac-form-textarea ${invalidFields.includes('content') ? 'gac-invalid' : ''}`}>
                    <textarea id='proof-content' value={content} readOnly />
                </div>
            </div>
            <div className='gac-meta-wordcount'>{`Word count: ${getWordcount(content)}`}</div>
        </div>;
    };
    const getKeywordsLevelField = () => {
        const currencySign = getCurrencySign(orderCurrency);
        let rates = ratesData[job_type].filter(o => o.currency === (isAuth ? currency : currencySign));
        let keywordsBasicRate = isEmpty(rates) ? '' : rates.filter((o) => o.level === 'standard')[0].amount;

        return <div className={`gac-form-row gac-form-level ${!isAuth ? 'gac-row-multiple gac-row-currency' : ''}`}>
            <div>
                <div className='gac-form-field gac-writer-level'>
                    <span className='gac-form-label'>Rate</span>
                    <div className='gac-form-field-content'>
                        <div id='level' className={`gac-form-radio ${invalidFields.includes('level') ? 'gac-invalid' : ''}`}>
                            <div data-value='standard' data-prop='level' className={`gac-radio-item ${level === 'standard' ? 'gac-item-active' : ''}`}>
                                <span className='gac-new'>{`${keywordsBasicRate ? `${(isAuth ? currency : currencySign)}${toLocaleString(`${keywordsBasicRate}`)}` : ''}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {getCurrencyField()}
        </div>;
    };
    const getTargetCountryField = () => {
        const items = countries.slice(0, 6).map(o => ({ value: o.code, title: o.name }));
        const title = getTitle(items, target_country_code);

        return (
            <div className='gac-form-row'>
                <div className='gac-form-field gac-field-1-2'>
                    <span className='gac-form-label'>Target country</span>
                    <Select
                        isMultiple={false}
                        items={items}
                        value={target_country_code ? target_country_code : ''}
                        title={title}
                        parameter='target_country_code'
                        invalid={invalidFields.includes('target_country_code')}
                        onChange={onFormFieldChange} />
                </div>
            </div>
        );
    };
    const getSmartMatchField = () => {
        return <div className='gac-form-row gac-row-match'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Writer match</span>
                <div className='gac-form-field-content'>
                    <div className='gac-form-radio'>
                        <div data-value='yes' className={`gac-radio-item ${smart_match === 'yes' ? 'gac-item-active' : ''}`} onClick={onSmartMatchChange}>Smart match</div>
                        <div data-value='no' className={`gac-radio-item ${smart_match === 'no' ? 'gac-item-active' : ''}`} onClick={onSmartMatchChange}>Review pitches</div>
                    </div>
                    <Hint text='Smart Match is a fast, reliable method to get the right writer for your project. Our editors review hundreds of projects each week and know which writer is the best match for your brief.' />
                </div>
            </div>
        </div>;
    };
    /* Blogging */
    const getTopicIdeasTypeField = () => {
        return <div className='gac-form-row gac-row-topic-ideas-type'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Topic ideas</span>
                <div className='gac-form-field-content'>
                    <div className={`gac-form-radio ${page === 'editing' ? 'gac-disabled' : ''}`}>
                        {!isNil(existingIdeas) && !isEmpty(existingIdeas) && <div data-value='existing' className={`gac-radio-item ${topic_ideas_type === 'existing' ? 'gac-item-active' : ''}`} onClick={onTopicIdeasTypeChange}>
                            Pick an existing idea
                        </div>}
                        <div data-value='request' className={`gac-radio-item ${topic_ideas_type === 'request' ? 'gac-item-active' : ''}`} onClick={onTopicIdeasTypeChange}>
                            Request ideas
                        </div>
                        <div data-value='own' className={`gac-radio-item ${topic_ideas_type === 'own' ? 'gac-item-active' : ''}`} onClick={onTopicIdeasTypeChange}>
                            Enter own topic
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    };
    const getExistingTopicIdeasField = () => {
        if (isEmpty(existingIdeas) || isNil(existingIdeas)) return null;
        let data = [];

        existingIdeas.forEach(a => {
            if (!isNil(a.ideas) && !isEmpty(a.ideas)) {
                a.ideas.forEach(b => {
                    const isChecked = `${activeIdea}` === `${b.id}`;
                    data.push(
                        <div className="gac-form-radio-input" key={b.id}>
                            <label htmlFor={`existing-idea-${b.id}`}>
                                <input type='radio' id={`existing-idea-${b.id}`} name='existing-ideas' value={b.id} checked={isChecked} onChange={onExistingIdeaChange} />
                                <span>{isChecked ? <CheckedRadio /> : <Radio />}</span>
                                {clearBrief(b.title)}
                            </label>
                        </div>
                    );
                });
            }
        });

        return isEmpty(data) ? null : data;
    };
    /* Design */
    const getServiceLevel = () => {
        const activeDesignSubscription = getActiveDesignSub(accountId, subscriptions);
        const isHourlyDesignSubscription = activeDesignSubscription?.is_hourly;

        if (job_pay_type === 'one-off' || isActiveWebBuildingSub(accountId, subscriptions) || isHourlyDesignSubscription) return 10;

        return activeDesignSubscription.service_level;
    };

    const getDesignFormatField = () => {
        const { design } = formats;
        let formatsData = design.filter(o => isNil(o.active) || !!o.active);

        // У one-off форматов обязательно должны  быть formats и options
        const oneOffFormats = formatsData
            .map(o => {
                return { ...o, formats: o.formats.filter(a => !isNil(a.options)) };
            }).filter(o => !isEmpty(o.formats) && !isNil(o.formats));

        let designFormats = job_pay_type === 'one-off' ? oneOffFormats : formatsData;

        // Обновляем designFormats в зависимости от того, какой тип: any, one-off или subscription
        const condition = type => type.type === 'any' || type.type === job_pay_type;
        designFormats = designFormats.map(item => ({...item, formats: item.formats.filter(condition)})).filter(item => item.formats.length > 0);
        
        const getServiceLevelData = () => {
            if (job_pay_type === 'one-off') return null;

            return (
                !isEmpty(format) && getDesignFormatServiceLevel(designFormats, format) > getServiceLevel() && (
                    <div className='gac-form-field gac-field-1-2' style={{ marginLeft: 10 }}>
                        <div className="gac-hint gac-hint-warning" style={{ marginTop: 40 }}>
                            <span style={{ cursor: 'default' }}>Available in Double only. <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { navigate('/designers-unlimited/pricing') }}>Upgrade</span>.</span>
                            <div className="gac-hint-wrap"><i style={{ cursor: 'default' }} /></div>
                        </div>
                    </div>
                )
            );
        };

        const title = getDesignFormat(designFormats, format);

        return <div className='gac-form-row gac-row-multiple'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Format</span>
                <Select
                    isGroups={true}
                    isSearch={true}
                    items={designFormats}
                    value={format ? `${format}` : ''}
                    title={title}
                    parameter='format'
                    service_level={getServiceLevel()}
                    invalid={invalidFields.includes('format')}
                    onChange={onFormFieldChange} />
            </div>
            {getServiceLevelData()}
            {getCurrencyField()}
        </div>;
    };
    const getDimensionsField = () => {
        const formatsData = formats[job_type];

        const isDisabled = getDesignFormatServiceLevel(formatsData, format) > getServiceLevel();
        const units = unit_dimensions['design'].map(o => ({ value: o.code, title: o.code }));
        const dimensionsData = getDimensions(formatsData, format);
        if (isNil(dimensionsData)) return null;
        const data = dimensionsData.map(o => {
            let custom_horizontal, custom_vertical, custom_unit_code;
            const item = dimensions.filter(a => `${a.id}` === `${o.id}`);
            if (!isEmpty(item)) {
                ({ custom_horizontal, custom_vertical, custom_unit_code } = item[0]);
            }

            return <div data-id={o.id} key={o.id} style={{ borderColor: invalidFields.includes('dimensions') ? '#dc5d5b' : null }}
                className={`gac-dimensions__item ${isEmpty(item) ? '' : 'gac-active'} ${isDisabled ? 'gac-disabled' : ''}`}
                onClick={isDisabled ? () => { } : onDimensionsSelect}>
                <div className='gac-dimensions__p' style={{ marginBottom: 1 }}>{o.title}</div>
                <div className='gac-dimensions__p'>
                    {isNil(o.horizontal)
                        ? <><input
                            data-id={o.id}
                            data-prop='custom_horizontal'
                            value={isNil(custom_horizontal) ? '' : custom_horizontal}
                            type='text'
                            onClick={(e) => { e.stopPropagation() }}
                            disabled={isDisabled}
                            placeholder={'width'}
                            onChange={isDisabled ? () => { } : onDimensionsInputChange} /> x </>
                        : <>{o.horizontal ? o.horizontal : ''}{o.vertical !== 0 && o.horizontal !== 0 ? ' x' : ''} </>}
                    {isNil(o.vertical)
                        ? <input
                            data-id={o.id}
                            data-prop='custom_vertical'
                            value={isNil(custom_vertical) ? '' : custom_vertical}
                            type='text'
                            onClick={(e) => { e.stopPropagation() }}
                            disabled={isDisabled}
                            placeholder={'height'}
                            onChange={isDisabled ? () => { } : onDimensionsInputChange} />
                        : <>{o.vertical ? o.vertical : ''}</>}
                    {isNil(o.unit) ? null : ` ${getShortUnit(o.unit)}`}
                    {isNil(o.unit)
                        ? <Select
                            items={units}
                            id={`${o.id}`}
                            value={!isNil(custom_unit_code) && !isEmpty(custom_unit_code) ? custom_unit_code : ''}
                            title={!isNil(custom_unit_code) && !isEmpty(custom_unit_code) ? units.filter(a => a.value === custom_unit_code)[0].title : ''}
                            parameter='custom_unit_code'
                            disabled={isDisabled}
                            onClick={(e) => { e.stopPropagation(); }}
                            onChange={isDisabled ? () => { } : onDimensionsUnitsChange} />
                        : null}
                </div>
                {!isEmpty(item) && <>
                    <div className='gac-dimensions__border-left' style={{ left: 0 }} />
                    <div className='gac-dimensions__border-right' style={{ right: 0 }} />
                    <div className='gac-dimensions__border-top' style={{ top: 0 }} />
                    <div className='gac-dimensions__border-bottom' style={{ bottom: 0 }} />
                </>}
            </div>;
        });
        return !isEmpty(data) && <div className='gac-form-row' style={{ marginBottom: 16 }}>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Dimensions</span>
                <div id='dimensions' className="gac-dimensions">
                    {data}
                </div>
            </div>
        </div>;
    };
    const getImagesField = () => {
        const imageFilesData = image_option_files.map(o => {
            return <li key={o.file_id}>
                <img src={o.url_resize} alt='' />
                <span data-id={o.file_id} onClick={onImageFilesClick}>
                    <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5" cy="5" r="5" fill="#fff" /><path fillRule="evenodd" clipRule="evenodd" d="M7.121 2.879a.5.5 0 00-.707 0L5 4.293 3.586 2.879a.5.5 0 00-.707.707L4.293 5 2.879 6.414a.5.5 0 00.707.707L5 5.707l1.414 1.414a.5.5 0 10.707-.707L5.707 5l1.414-1.414a.5.5 0 000-.707z" fill="#59C69F" /></svg>
                </span>
            </li>;
        });

        return <div className='gac-form-row' style={{ marginBottom: 16 }}>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Images</span>
                <div className="gac-design-images">
                    <ul id='image_option' className={invalidFields.includes('image_option') ? 'gac-invalid' : ''}>
                        <li
                            data-value='browse-images'
                            className={image_option === 'browse-images' ? 'gac-active' : ''}
                            onClick={onDesignImagesRadioClick}>
                            <i>
                                <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.4 6.4H0v3.2h6.4V16h3.2V9.6H16V6.4H9.6V0H6.4v6.4z" fill="#59C69F" /></svg>
                            </i>
                            <p>Browse images</p>
                        </li>
                        <li
                            data-value='find-images-for-me'
                            className={image_option === 'find-images-for-me' ? 'gac-active' : ''}
                            onClick={onDesignImagesRadioClick}>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25.6" height="22"><path fill="#59C69F" d="M21.2 9.7c0 2.4-.9 4.6-2.6 6.3-3.1 3-7.8 3.5-11.4 1.1l-.3-.2-4.8 4.8c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-.4-.4c-.4-.4-.4-1 0-1.4L5 15l-.2-.3C2.4 11.1 2.9 6.3 5.9 3.3 7.6 1.6 9.9.7 12.3.7h.8l-.4.4c-.6.6-.9 1.3-.9 2.1-1.5.1-2.9.7-4 1.8-2.5 2.5-2.5 6.6 0 9.1s6.6 2.5 9.1 0c1.1-1.1 1.7-2.5 1.8-3.9.8 0 1.7-.2 2.3-.9l.3-.3c-.1.3-.1.5-.1.7z" /><path fill="#59C69F" d="M25.3.7l-.4-.4c-.4-.4-1-.4-1.4 0l-3.2 3.2-.2.2-.3.3-1.1 1.1-1.4-1.4-1.5-1.5c-.4-.4-1-.4-1.4 0l-.4.4c-.2.2-.3.6-.3.9 0 .2.1.4.3.5l4 4 .1.1c.1.1.2.1.3.2.3.1.7 0 1-.3L21 6.3l.4-.4 3.9-3.7c.4-.4.4-1.1 0-1.5zm-.4 1.1z" /></svg>
                            </i>
                            <p>Find images for me</p>
                        </li>
                        <li
                            data-value='use-attached'
                            className={image_option === 'use-attached' ? 'gac-active' : ''}
                            onClick={onDesignImagesRadioClick}>
                            <i>
                                <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.9307 20.0281C-0.643565 17.3989 -0.643565 13.1438 1.9307 10.5146L10.8221 1.43351C12.6935 -0.477838 15.725 -0.477838 17.5964 1.43351C19.4679 3.34487 19.4679 6.44108 17.5964 8.35243L10.3987 15.7038C9.23008 16.8973 7.33326 16.8973 6.16468 15.7038C4.9961 14.5103 4.9961 12.573 6.16468 11.3795L11.6689 5.75784C12.1365 5.28019 12.8948 5.28019 13.3625 5.75784C13.8301 6.23549 13.8301 7.00992 13.3625 7.48757L7.78206 13.187C7.31632 13.6627 8.16312 14.5276 8.62886 14.0519L15.9028 6.6227C16.8343 5.67135 16.8343 4.11459 15.9028 3.16324C14.9714 2.21189 13.4471 2.21189 12.5157 3.16324L3.62429 12.2443C1.98997 13.9135 1.98997 16.6292 3.62429 18.2984C5.25861 19.9676 7.91755 19.9676 9.55187 18.2984L16.7496 10.947C17.2173 10.4694 17.9756 10.4694 18.4432 10.947C18.9109 11.4247 18.9109 12.1991 18.4432 12.6768L11.2455 20.0281C8.6712 22.6573 4.50496 22.6573 1.9307 20.0281Z" fill="#59C69F" /></svg>
                            </i>
                            <p>Use attached images</p>
                        </li>
                        <li
                            data-value='do-not-use'
                            className={image_option === 'do-not-use' ? 'gac-active' : ''}
                            onClick={onDesignImagesRadioClick}>
                            <i>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.42442 7.86126L0.273438 13.0122L2.84892 15.5877L7.99991 10.4367L13.1509 15.5878L15.7264 13.0123L10.5754 7.86126L15.7263 2.7103L13.1509 0.134819L7.99991 5.28577L2.84899 0.134858L0.27351 2.71034L5.42442 7.86126Z" fill="#59C69F" /></svg>
                            </i>
                            <p>Do not use images</p>
                        </li>
                    </ul>
                </div>
                {!isEmpty(imageFilesData) && <ul className="gac-design-files">
                    {imageFilesData}
                    <li className='gac-add-new-file' onClick={() => { setModal('images') }}>
                        <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.5 0h-1v9.5H0v1h9.5V20h1v-9.5H20v-1h-9.5V0z" fill="#59C69F" /></svg>
                    </li>
                </ul>}
            </div>
        </div>;
    };
    const getImageNotesField = () => {
        return !!image_option && includes(image_option, 'browse-images/find-images-for-me') && getTextareaField('image_notes', image_option === 'browse-images' ? 'Add notes about selected images' : 'What are you looking for?');
    };
    const getBrandProfilesField = () => {
        const data = brandProfiles
            .filter(({ account_uid }) => account_uid === accountId)
            .filter(o => o.active)
            .map(({ id, name, colors }) => {
                return <li key={id} data-value={id} className={includes(id, brand_profiles) ? 'gac-active' : ''} onClick={onBrandProfileRadioClick}>
                    <i style={{ backgroundColor: isNil(colors) || isEmpty(colors) ? '#59C69F' : colors[0], border: isNil(colors) || isEmpty(colors) ? null : colors[0] === '#ffffff' ? '2px solid #D9D9D9' : null }} />
                    <p title={name}>{name}</p>
                    <span data-id={id} className="gac-edit" onClick={onBrandProfileEdit} />
                </li>;
            });

        return <div className='gac-form-row' style={{ marginBottom: 16 }}>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Brand profile</span>
            </div>
            <div className="gac-brand-profiles">
                <ul>
                    {data}
                    <li data-value='create-new-profile' onClick={onBrandProfileRadioClick}>
                        <i>
                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="11" stroke="#59C69F" strokeWidth="2" /><path d="M11 7a1 1 0 112 0v10a1 1 0 11-2 0V7z" fill="#59C69F" /><path d="M7 13a1 1 0 110-2h10a1 1 0 110 2H7z" fill="#59C69F" /></svg>
                        </i>
                        <p>Create new profile</p>
                    </li>
                </ul>
            </div>
        </div>;
    };
    const getFileTypesField = () => {
        let data = file_types['design'].filter(o => o.code !== 'fig').filter(o => o.code !== 'gif');
        const fig = file_types['design'].filter(o => o.code === 'fig')[0];
        const gif = file_types['design'].filter(o => o.code === 'gif')[0];
        const index = data.findIndex(o => o.code === 'ai');
        if (!isNil(fig) && index >= 0) {
            data.splice(index, 0, fig);
        }
        if (!isNil(gif) && index >= 0) {
            data.splice(index, 0, gif);
        }

        if (job_type === 'motion') {
            data = data.filter(o => includes(o.code, 'gif/mp4/mov/ae/lottie'));
        } else {
            if (`${format}` === '114') { //Animation & GIF
                data = data.filter(o => includes(o.code, 'gif/mp4/mov/webm/ae/lottie'));
            } else {
                data = data.filter(o => !includes(o.code, 'gif/mp4/mov/webm/ae/lottie'));
            }
            if (`${format}` === '101') { //Presentation & Pitch Deck
                data = data.filter(o => includes(o.code, 'jpg/png/pdf/fig/indd/ppt/key/gslides'));
            } else {
                data = data.filter(o => !includes(o.code, 'ppt/key/gslides'));
            }
        }
        if (!isNil(used_services) && includes('canva', used_services)) {
            data = data.filter(o => !includes(o.code, 'svg/esp/fig/ai/psd/indd/lottie'));
        }

        const getHint = () => {
            if (job_type !== 'design' || isEmpty(format) || !includes(format, '106/107')) return null;

            return <div className='gac-hint gac-hint-success'><span>Figma is recommended</span></div>;
        };

        data = data.map(o => {
            return <div key={o.code} data-value={o.code} data-prop='file_types'
                style={{ borderColor: invalidFields.includes('file_types') ? '#dc5d5b' : null, marginLeft: o.code === 'fig' && !isMobile ? 40 : null }}
                className={`gac-file-types__item ${includes(o.code, formFileTypes) ? 'gac-file-types__active' : ''}`}
                onClick={onFileTypesChange}>
                {o.name}
            </div>;
        });

        return <div className='gac-form-row' style={{ marginBottom: 16 }}>
            <div className='gac-form-field'>
                <span className='gac-form-label'>File types</span>
                <div id='file_types' className="gac-file-types">{data}</div>
                {getHint()}
            </div>
        </div>;
    };
    const getPriorityField = () => {
        if (isNil(projectsDesignTotal)) return null;

        const title = getTitle(priorities, priority);
        return <div className='gac-form-row'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Priority</span>
                <Select
                    direction={isMobile ? 'top' : null}
                    items={priorities}
                    value={priority ? priority : ''}
                    title={title}
                    parameter='priority'
                    invalid={invalidFields.includes('priority')}
                    onChange={onFormFieldChange} />
            </div>
        </div>;
    };
    const getReviewFrequencyField = () => {
        const config = [
            { value: 'every_business_day', title: 'Every business day' },
            { value: 'upon_project_completion', title: 'Upon completion of the project' },
        ];

        const title = getTitle(config, review_frequency);
        return <div className='gac-form-row gac-review-frequency-row'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>I would like to receive and review creative/creatives</span>
                <Select
                    direction={isMobile ? 'top' : null}
                    items={config}
                    value={review_frequency ? review_frequency : ''}
                    title={title}
                    parameter='review_frequency'
                    invalid={invalidFields.includes('review_frequency')}
                    onChange={onFormFieldChange} />
                {!isEmpty(review_frequency) && <Hint text={[]} type='success' title="What's the difference">
                    <>
                        {review_frequency === 'every_business_day' && <div>If the job is complex, a part of it will be submitted each business day. Approval or revision request will be needed after each submission.</div>}
                        {review_frequency === 'upon_project_completion' && <div>First, the stylistic direction should be approved</div>}
                    </>
                </Hint>}
            </div>
        </div>;
    };
    const getWorkflowField = () => {
        if (isEmpty(format) || !includes(format, '106/107')) return null;

        const config = [
            { value: 'moodboard', label: 'Moodboard', getSvg: () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" fill="none"><path fill="#61A89C" d="M0 2.361A2.361 2.361 0 0 1 2.361 0H21.25a2.36 2.36 0 0 1 2.361 2.361V18.89a2.361 2.361 0 0 1-2.361 2.361H2.361A2.361 2.361 0 0 1 0 18.889V2.36Zm7.083 0H2.361V18.89h4.722V2.36Zm2.361 0V18.89h4.723V2.36H9.444Zm7.084 0V18.89h4.722V2.36h-4.722Z" /></svg> },
            { value: 'wireframes', label: 'Wireframes', getSvg: () => <svg xmlns="http://www.w3.org/2000/svg" width="42" height="27" fill="none"><path fill="#61A89C" d="M0 0v10.5h10.5V0H0Zm9.188 9.188H1.311V1.311h7.875v7.875Z" /><path fill="#61A89C" d="M0 0v10.5h10.5V0H0Zm9.188 9.188H1.311V1.311h7.875v7.875ZM15.75 0v10.5h10.5V0h-10.5Zm9.188 9.188h-7.875V1.311h7.875v7.875Z" /><path fill="#61A89C" d="M15.75 0v10.5h10.5V0h-10.5Zm9.188 9.188h-7.875V1.311h7.875v7.875ZM31.5 0v10.5H42V0H31.5Zm9.188 9.188h-7.876V1.311h7.876v7.875Z" /><path fill="#61A89C" d="M31.5 0v10.5H42V0H31.5Zm9.188 9.188h-7.876V1.311h7.876v7.875ZM0 15.75v10.5h10.5v-10.5H0Zm9.188 9.188H1.311v-7.875h7.875v7.875Z" /><path fill="#61A89C" d="M0 15.75v10.5h10.5v-10.5H0Zm9.188 9.188H1.311v-7.875h7.875v7.875ZM15.75 15.75v10.5h10.5v-10.5h-10.5Zm9.188 9.188h-7.875v-7.875h7.875v7.875Z" /><path fill="#61A89C" d="M15.75 15.75v10.5h10.5v-10.5h-10.5Zm9.188 9.188h-7.875v-7.875h7.875v7.875ZM31.5 15.75v10.5H42v-10.5H31.5Zm9.188 9.188h-7.876v-7.875h7.876v7.875Z" /><path fill="#61A89C" d="M31.5 15.75v10.5H42v-10.5H31.5Zm9.188 9.188h-7.876v-7.875h7.876v7.875Z" /></svg> },
        ];
        const data = config.map(o => {
            return <li key={o.value} data-value={o.value} className={includes(o.value, workflow) ? 'gac-active' : ''} onClick={onWorkflowRadioClick}>
                <i>{o.getSvg()}</i>
                <p>{o.label}</p>
            </li>;
        });

        return <div className='gac-form-row gac-workflow-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Workflow</span>
                <div className="gac-design-workflow">
                    <ul>{data}</ul>
                </div>
                <Hint
                    text={[]}
                    type='success'
                    title='Why do we need this?'>
                    <>
                        <div>Moodboards help capture the desired visual style and aesthetics, effectively communicating the preferences. We recommend it as a great starting point for website design.</div>
                        <div>Wireframes, on the other hand, help outline the webpage's structure and layout, facilitating efficient planning and alignment between requirements and the outcome.</div>
                    </>
                </Hint>
            </div>
        </div>;
    };
    const getSitemapField = () => {
        if (isEmpty(format) || !includes(format, '106/107')) return null;

        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Sitemap</span>
                <div className='gac-form-input'>
                    <input {...inputProps} id='sitemap' value={sitemap} onChange={onInputFieldChange} />
                </div>
            </div>
        </div>;
    };
    const getUsedServicesField = () => {
        return <div className='gac-form-row gac-used-services-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Used services</span>
                <div className='gac-form-radio'>
                    <div onClick={onUsedServicesChange} data-value='canva' data-prop='used_services' className={`gac-radio-item ${includes('canva', used_services) ? 'gac-item-active' : ''}`}>
                        <span>Canva</span>
                    </div>
                </div>
                <Hint
                    text={[]}
                    type='success'
                    title="Why do we not recommend using Canva">
                    <div>Canva has certain limitations compared to professional software like Adobe Illustrator, InDesign, Photoshop, and Figma. It primarily works with templates and lacks advanced features for complex projects, potentially leading to compromises in design. Prepress capabilities are limited, making it challenging for clients who require precise print preparations. Additionally, design originality may be compromised as Canva offers a limited font selection and restricts custom font uploads. Complex design requirements may also be harder to accommodate. Another limitation is the inability to download designs on a transparent background. Furthermore, all work on Canva is stored in their cloud, which may lead to temporary loss of files if the service encounters issues. Clients should consider these limitations before choosing Canva for their design needs.</div>
                </Hint>
            </div>
        </div>;
    };
    /* Motion */
    const getMotionFormatField = () => {
        const { motion } = formats;
        const formatsData = motion.filter(o => isNil(o.active) || !!o.active);
        const title = getDesignFormat(formatsData, format);

        return <div className='gac-form-row gac-row-multiple'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Format</span>
                <Select
                    isGroups={true}
                    isSearch={true}
                    items={formatsData}
                    value={format ? `${format}` : ''}
                    title={title}
                    parameter='format'
                    invalid={invalidFields.includes('format')}
                    onChange={onFormFieldChange} />
            </div>
            {getCurrencyField()}
        </div>;
    };
    const getFormatOptionsField = () => {
        const currencyCode = isAuth ? getCurrencyCode(currency) : orderCurrency;
        const currencySign = getCurrencySign(currencyCode);

        if (isEmpty(format) || isNil(format)) return null;

        const optionsArr = formats[job_type].filter(o => o.formats.some(a => `${a.id}` === `${format}`))[0].formats.filter(a => `${a.id}` === `${format}`)[0].options.filter(o => !!o.active);

        if (isNil(optionsArr) || isEmpty(optionsArr)) return null;

        const data = optionsArr
            .sort((a, b) => a.sort - b.sort)
            .map(o => {
                const { id, title, description, multiple, custom } = o;
                const option = format_options.filter(o => `${o.id}` === `${id}`);
                const value = option.length;
                const customValue = isNil(option) || isEmpty(option) ? '' : option[0].amount;
                const priceValue = !!value ? Number(o[`price_${currencyCode}`]) * value : o[`price_${currencyCode}`];
                const isActive = format_options.some(o => `${o.id}` === `${id}`);

                return <div key={id} data-id={id} data-custom={custom} className={`gac-format-options__item ${isActive ? 'gac-active' : ''} ${!!multiple ? 'gac-multiple' : ''}`} style={{ borderColor: invalidFields.includes('format_options') ? '#dc5d5b' : null }} onClick={onFormatOptionChange}>
                    <div className="gac-format-options__title">{title}</div>
                    {description && <div className="gac-format-options__p" dangerouslySetInnerHTML={{ __html: description }} />}
                    {!!multiple && <div className="gac-format-options__input">
                        <span onClick={onFormatOptionDecrease} data-id={id} data-value={value - 1} className='gac-decrease-qty'><MinusGrey /></span>
                        <input type='number' data-id={id} value={value} data-hj-whitelist onChange={onFormatOptionInputChange} onClick={onFormatOptionInputClick} />
                        <span onClick={onFormatOptionIncrease} data-id={id} data-value={value + 1} className='gac-increase-qty'><PlusGrey /></span>
                    </div>}
                    {!custom && <div className="gac-format-options__price">{currencySign}{toLocaleString(priceValue)}</div>}
                    {!!custom && <div className="gac-format-options__custom">
                        {currencySign}
                        <input type='text' data-id={id} id='custom_quote' style={{ borderColor: invalidFields.includes('custom_quote') ? '#dc5d5b' : null }} value={customValue ? toLocaleString(customValue) : ''} data-hj-whitelist onChange={onCustomQuoteInputChange} onClick={onFormatOptionInputClick} />
                    </div>}
                    {isActive && <>
                        <div className='gac-format-options__border-left' style={{ left: 0 }} />
                        <div className='gac-format-options__border-right' style={{ right: 0 }} />
                        <div className='gac-format-options__border-top' style={{ top: 0 }} />
                        <div className='gac-format-options__border-bottom' style={{ bottom: 0 }} />
                    </>}
                </div>;
            });

        return <div className='gac-form-row' style={{ marginBottom: 8 }}>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Options</span>
                <div id='format_options' className="gac-format-options">
                    {data}
                </div>
            </div>
        </div>;
    };
    const getAttachFileField = () => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Attach file (.eps or .ai file preferred)</span>
                {getDropzoneMotion()}
            </div>
        </div>;
    };
    const getMotionAnimationLengthField = () => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Animation duration</span>
                <div className='gac-form-input'>
                    <input {...inputProps} id='length' className={invalidFields.includes('length') ? 'gac-invalid' : ''} value={animationLength} onChange={onInputFieldChange} />
                </div>
            </div>
        </div>;
    };
    /* WebAudit */
    const getWebAuditLevelField = () => {
        const currencySign = getCurrencySign(orderCurrency);
        const currencyStr = isAuth ? currency : currencySign
        let rates = ratesData[job_type].filter(o => !!o.active)
            .filter(o => o.currency === currencyStr);
        let fullAmount = isEmpty(rates) ? '' : rates.filter((o) => o.level === 'standard')[0].full_amount;
        let amount = isEmpty(rates) ? '' : rates.filter((o) => o.level === 'standard')[0].amount;

        return <div className={`gac-form-row gac-form-level ${!isAuth ? 'gac-row-multiple gac-row-currency' : ''}`}>
            <div>
                <div className='gac-form-field gac-writer-level'>
                    <span className='gac-form-label'>Rate</span>
                    <div className='gac-form-field-content'>
                        <div id='level' className={`gac-form-radio ${invalidFields.includes('level') ? 'gac-invalid' : ''}`}>
                            <div data-value='standard' data-prop='level' className={`gac-radio-item ${level === 'standard' ? 'gac-item-active' : ''}`}>
                                {fullAmount !== amount && <span style={{ textDecoration: 'line-through', marginRight: 10 }}>{`${fullAmount ? `${(currencyStr)}${toLocaleString(`${fullAmount}`)}` : ''}`}</span>}
                                <span>{`${amount ? `${(currencyStr)}${toLocaleString(`${amount}`)}` : ''}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {getCurrencyField()}
        </div>;
    };
    const getCmsField = (classStr, minHeight = 24) => {
        const items = cmsData
            .filter(o => o.active)
            .sort((a, b) => a.sort - b.sort)
            .map(o => ({ value: `${o.id}`, title: o.name }));

        return <div className={`gac-form-field ${classStr}`}>
            <span className='gac-form-label' style={{ minHeight: isMobile ? 24 : minHeight }}>Website content management system</span>
            <Select
                items={items}
                value={cms}
                title={cms ? cmsData.filter(o => `${o.id}` === cms)[0].name : ''}
                parameter='cms'
                invalid={invalidFields.includes('cms')}
                onChange={onFormFieldChange} />
        </div>;
    };
    const getWebsiteField = () => {
        return <div className='gac-form-row gac-row-multiple'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Link to the website</span>
                <div className='gac-form-input'>
                    <input {...inputProps} id='website' className={invalidFields.includes('website') ? 'gac-invalid' : ''} value={website} onChange={onInputFieldChange} />
                </div>
            </div>
            {getCmsField('gac-field-1-2')}
        </div>;
    };
    const getGoogleServicesField = () => {
        const items = [
            { value: 'yes', title: 'Yes' },
            { value: 'no', title: 'No' },
            { value: 'unknown', title: `I don't know` },
        ];

        return <div className='gac-form-row gac-row-multiple'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Do you use Google Analytics?</span>
                <Select
                    items={items}
                    value={google_analytics}
                    title={google_analytics ? items.filter(o => `${o.value}` === google_analytics)[0].title : ''}
                    parameter='google_analytics'
                    invalid={invalidFields.includes('google_analytics')}
                    onChange={onFormFieldChange} />
            </div>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Do you use Google Search Console?</span>
                <Select
                    items={items}
                    value={google_search_console}
                    title={google_search_console ? items.filter(o => `${o.value}` === google_search_console)[0].title : ''}
                    parameter='google_search_console'
                    invalid={invalidFields.includes('google_search_console')}
                    onChange={onFormFieldChange} />
            </div>
        </div>;
    };
    const getLinkToResourcesField = () => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Link to the company's blog/resources page</span>
                <div className='gac-form-input'>
                    <input {...inputProps}
                        id={job_type === 'seoaudit' ? 'resources' : 'link_to_resources'}
                        className={invalidFields.includes(job_type === 'seoaudit' ? 'resources' : 'link_to_resources') ? 'gac-invalid' : ''}
                        value={job_type === 'seoaudit' ? resources : link_to_resources} onChange={onInputFieldChange} />
                </div>
            </div>
        </div>;
    };
    /* SeoAudit */
    const getSeoAuditTypeField = () => {
        const config = [
            { value: 'technical', label: 'Technical SEO audit' },
            { value: 'competitor', label: 'Competitor SEO audit' },
            { value: 'full', label: 'Full SEO audit' },
        ];
        const html = config.map(({ value, label }) => {
            return <div key={value} data-value={value} onClick={onSeoAuditTypeChange} className={`gac-radio-item ${seoaudit_type === value ? 'gac-item-active' : ''}`}>{label}</div>;
        });

        return <div className='gac-form-row gac-row-topic-ideas-type'>
            <div className='gac-form-field'>
                <div className='gac-form-field-content'>
                    <div className={`gac-form-radio ${page === 'editing' ? 'gac-disabled' : ''}`}>
                        {html}
                    </div>
                </div>
            </div>
        </div>;
    };
    const getSeoAuditLevelField = () => {
        const currencySign = getCurrencySign(orderCurrency);
        const currencyStr = isAuth ? currency : currencySign;
        let rates = ratesData[job_type].filter(o => o.currency === currencyStr).filter(o => !!o.active);
        let rate = isEmpty(rates) ? '' : rates.filter((o) => o.type === seoaudit_type)[0].amount;

        return <div className={`gac-form-row gac-form-level ${!isAuth ? 'gac-row-multiple gac-row-currency' : ''}`}>
            <div>
                <div className='gac-form-field gac-writer-level'>
                    <span className='gac-form-label'>Rate</span>
                    <div className='gac-form-field-content'>
                        <div className='gac-form-radio'>
                            <div className='gac-radio-item gac-item-active'>
                                <span className='gac-new'>{`${rate ? `${currencyStr}${toLocaleString(`${rate}`)}` : ''}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {getCurrencyField()}
        </div>;
    };
    const getSeoAuditWebsiteField = () => {
        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Link to the website</span>
                <div className='gac-form-input'>
                    <input {...inputProps} id='website' className={invalidFields.includes('website') ? 'gac-invalid' : ''} value={website} onChange={onInputFieldChange} />
                </div>
            </div>
        </div>;
    };
    const getSeoAuditedField = () => {
        const items = [
            { value: 'yes', title: 'Yes' },
            { value: 'no', title: 'No' },
            { value: 'unknown', title: `I don't know` },
        ];

        return <div className='gac-form-row gac-row-multiple'>
            <div className='gac-form-field gac-field-1-2'>
                <span className='gac-form-label'>Have you ever had an SEO audit performed on your website before?</span>
                <Select
                    items={items}
                    value={seo_audited}
                    title={seo_audited ? items.filter(o => `${o.value}` === seo_audited)[0].title : ''}
                    parameter='seo_audited'
                    onChange={onFormFieldChange} />
            </div>
            {getCmsField('gac-field-1-2', 48)}
        </div>;
    };
    const getGoogleAccessField = () => {
        const items = [
            { value: 'done', title: 'Done' },
            { value: 'help', title: 'Need help' },
        ];

        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Please send access to Google Analytics and Google Search Console to audits@epiic.com</span>
                <Select
                    direction={isMobile ? 'top' : null}
                    items={items}
                    value={google_access}
                    title={google_access ? items.filter(o => `${o.value}` === google_access)[0].title : ''}
                    parameter='google_access'
                    invalid={invalidFields.includes('google_access')}
                    onChange={onFormFieldChange} />
            </div>
        </div>;
    };
    const getLinkAnalyzedField = () => {
        const items = [
            { value: 'yes', title: 'Yes' },
            { value: 'no', title: 'No' },
            { value: 'unknown', title: `I don't know` },
        ];

        return <div className='gac-form-row'>
            <div className='gac-form-field'>
                <span className='gac-form-label'>Have you ever had a disavow link analysis performed on your website before?</span>
                <Select
                    items={items}
                    value={link_analized}
                    title={link_analized ? items.filter(o => `${o.value}` === link_analized)[0].title : ''}
                    parameter='link_analized'
                    invalid={invalidFields.includes('link_analized')}
                    onChange={onFormFieldChange} />
            </div>
        </div>;
    };
    const getSeoAuditCmsField = () => {
        return <div className='gac-form-row'>
            {getCmsField('')}
        </div>;
    };

    /* Btns */
    const getBtn = (type) => {
        const { project_id } = editedProject;
        // if ( type === 'draft' && isEpiic(host) && job_type === 'blogging' && topic_ideas_type === 'request' ) return null;

        const isInOrder = orderList.some(o => `${o.project_id}` === `${project_id}`);

        if (type === 'draft') {
            return (
                isAuth && (
                    <span
                        style={{ marginRight: includes(job_type, 'design/motion') && !isMobile ? 20 : null }}
                        data-action={`${isInOrder ? 'update_draft_order' : page === 'editing' ? 'update_draft' : 'save_draft'}`}
                        onClick={onSubmit}
                        className='gac-btn-v2 gac-btn-s'
                    >
                        Save draft
                    </span>
                )
            )
        }

        return !isInOrder && <span data-action={`${page === 'editing' ? 'update_add_to_order' : 'save_add_to_order'}`} onClick={onSubmit} className='gac-btn gac-btn-s'>{includes(job_type, 'writing/design/motion/blogging') && job_pay_type === 'subscription' ? 'Place order' : 'Add to Order'}</span>;
    };

    const getBtns = () => {
        const activeDesignSubscription = getActiveDesignSub(accountId, subscriptions);
        const planName = activeDesignSubscription?.plan_name;

        if (isNil(job_group) && !isSelfClientFn(user_role)) return null;
        if (isNil(job_type) || (includes(job_type, 'writing/design/motion/blogging') && isNil(job_pay_type))) return null;
        if (job_type === 'seoaudit' && isNil(seoaudit_type)) return null;
        if (job_type === 'design' && job_pay_type === 'subscription' && !isActiveDesignSub(accountId, subscriptions) && !isActiveWebBuildingSub(accountId, subscriptions)) return null;
        if (job_type === 'motion' && job_pay_type === 'subscription' && !isActiveDesignSub(accountId, subscriptions) && !isActiveWebBuildingSub(accountId, subscriptions)) return null;
        if (job_type === 'motion' && job_pay_type === 'subscription' && isActiveDesignSub(accountId, subscriptions) && (planName === 'Pro' || planName === 'Double')) return null;
        if (job_pay_type === 'subscription' && job_type === 'blogging' && (type !== 'bloggers' || words_balance < 1 || !words_balance)) return null;
        if (job_pay_type === 'subscription' && job_type === 'writing' && (type !== 'writers' || words_balance < 1 || !words_balance)) return null;

        const getCutoffTime = () => {
            if (job_pay_type === 'one-off') return null;
            if (!isActiveDesignSub(accountId, subscriptions)) return null;
            const { cutoff_time: { time, timezone } } = getActiveDesignSub(accountId, subscriptions);

            return <>{includes(job_type, 'design/motion') && <span className='gac-cutoff-time'>
                Cutoff: {time} {timezone} <Hint text={`Orders placed after the cutoff time will be worked on during the next day's shift.`} type='default' title='' />
            </span>}</>;
        };

        return <div className='gac-form-btns'>
            {getBtn('order')}
            {getBtn('draft')}
            {getCutoffTime()}
        </div>;
    };

    return {
        isDetails,
        getProjectTitle,
        getJobType,
        getFormatField,
        getIndustryField,
        getQuantityField,
        getLevelField,
        getTopicWritingField,
        getBriefField,
        getWritersField,
        getTurnaroundField,
        getOrderOnField,
        getAdditionalDetailsLabel,
        getLocalizationField,
        getTagsField,
        getCopyStyleVoiceField,
        getAdditionalDetailsFields,
        getTopicField,
        getTextareaField,
        getTargetAudienceField,
        getContentGoalField,
        getPlannedWordCountField,
        getWordCountSimpleField,
        getContentToProofreadField,
        getProofreadingWordCountField,
        getKeywordsLevelField,
        getTargetCountryField,
        getDesignFormatField,
        getDimensionsField,
        getImagesField,
        getImageNotesField,
        getBrandProfilesField,
        getFileTypesField,
        getPriorityField,
        getTopicIdeasTypeField,
        getSmartMatchField,
        getExistingTopicIdeasField,
        getExistingIdeaObj,
        getMotionFormatField,
        getAttachFileField,
        getMotionAnimationLengthField,
        getFormatOptionsField,
        getWebAuditLevelField,
        getWebsiteField,
        getGoogleServicesField,
        getLinkToResourcesField,
        getSeoAuditTypeField,
        getSeoAuditLevelField,
        getSeoAuditWebsiteField,
        getSeoAuditedField,
        getGoogleAccessField,
        getLinkAnalyzedField,
        getSeoAuditCmsField,
        getMotionIdeasDropzoneHtml,
        getWorkflowField,
        getSitemapField,
        getUsedServicesField,
        getIsAllTopicsOneDayCheckbox,
        getReviewFrequencyField,
        getBtns,

        onFormDrop,
        onFormDragStart,
        onRedactorSynced,
        onRedactorBlur,
        onSubmit,
    };
};